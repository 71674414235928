import type { AxiosResponse } from 'axios';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAllBuildings } from 'admin/store/allBuildings';
import AllEvents from '../TabPages/AllEvents';
import MyEvents from '../TabPages/MyEvents';
import Reports from '../TabPages/Reports';
import RecentActivities from '../TabPages/RecentActivities';
import type { BuildingsResponse } from '../types';
import AllChecklists from '../TabPages/AllChecklists/index';
import CalendarPage from '../TabPages/CalendarPage';

export interface TabsProps {
  id: number;
  heading: string;
  component: JSX.Element;
}

export default function Tabs(): JSX.Element {
  const { tabId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState<TabsProps>({
    id: 1,
    heading: 'My events',
    component: <div> my events</div>,
  });

  const { data: buildingList = [] } = useQuery(
    ['get/buildings'],
    async () => axios({
      url: 'api/property-manager-property/',
      method: 'get',
    }),
    {
      // select: (res: AxiosResponse<BuildingsResponse>) => res.data.result,
      onSuccess: (res: AxiosResponse<BuildingsResponse>) => {
        dispatch(setAllBuildings(res.data.result));
      },
    },

  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tabs: TabsProps[] = useMemo(() => [
    {
      id: 1,
      heading: 'My events',
      component: <MyEvents />,
    },
    {
      id: 2,
      heading: 'All events',
      component: <AllEvents />,
    },
    {
      id: 3,
      heading: 'All Checklists',
      component: <AllChecklists />,
    },
    {
      id: 4,
      heading: 'Calendar',
      component: <CalendarPage />,
    },
    {
      id: 5,
      heading: 'Reports',
      component: <Reports />,
    },
    {
      id: 6,
      heading: 'Recent activity',
      component: <RecentActivities />,
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ], []);

  useEffect(() => {
    const currentTab = tabs.filter((tab) => tab.id === Number(tabId));
    if (currentTab.length > 0) {
      setActiveTab(currentTab[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabId, buildingList]);

  return (
    <div className='tabs-page'>
      <div className='tabs-wrapper'>
        {tabs.map((tab) => (
          <div
            onClick={(): void => {
              navigate(`/workspace/dashboard/${tab.id}`);
            }}
            aria-hidden='true'
            className={
              tab.id === activeTab.id ? 'single-tab active' : 'single-tab'
            }
            key={tab.heading}
          >
            {' '}
            <h6>
              {' '}
              {tab.heading}
              {' '}
            </h6>
            {' '}
          </div>
        ))}
      </div>

      {activeTab.component}
    </div>
  );
}
