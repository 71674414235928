/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  InputBase, Table, TableBody, TableCell, TableHead, TableRow, Avatar,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import StatusTag from 'shared-components/components/StatusTag';
import type {
  IEvent,
  ITableSectionProps,
} from 'admin/buildingSection/budget-calendar/types';
import SideSheet from 'admin/buildingSection/budget-calendar/components/SideSheet';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { setSideSheetData } from 'admin/store/SideSheetData';
import '../Reports/_reports.scss';

interface TableSectionProps extends ITableSectionProps {
  setgetNextData: (value: boolean) => void;
}

export default function TableSection(props: TableSectionProps): JSX.Element {
  const {
    events, newEventOpen, setNewEventOpen, createEvent, setgetNextData,
  } = props;
  const [title, setTitle] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [dataToEdit, setDataToEdit] = useState<IEvent | null>(null);
  const { ref, inView } = useInView();
  const dispatch = useDispatch();

  const handleOnBlur = (): void => {
    if (title !== '') {
      createEvent(title);
      setTitle('');
    }
    setNewEventOpen(false);
  };
  const handleOnEnterPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (e.key === 'Enter' && title !== '') {
      setNewEventOpen(false);
      createEvent(title);
      setTitle('');
    }
  };

  const handleRowClick = (data: IEvent): void => {
    setOpen(true);
    setDataToEdit(data);
  };

  const handleClose = (): void => {
    setOpen(false);
    setDataToEdit(null);
  };

  useEffect(() => {
    if (open && dataToEdit !== null) {
      const newDataToEdit = events.filter(
        (event) => event.id === dataToEdit.id,
      );
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (newDataToEdit[0]) {
        setDataToEdit(newDataToEdit[0]);
      } else {
        setDataToEdit(null);
        setOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  useEffect(() => {
    dispatch(setSideSheetData(dataToEdit));
  }, [dataToEdit]);

  useEffect(() => {
    if (inView) {
      setgetNextData(true);
    } else {
      setgetNextData(false);
    }
  }, [inView]);

  return (
    <div>
      {/* <BudgetCalendarTable
        columns={columns}
        data={events}
        handleRowClick={handleRowClick}
      />
      <div className='new-event-block'>
        {newEventOpen && (
          <InputBase
            value={title}
            className='new-event-input'
            placeholder='Write a Event Name'
            onChange={(e): void => {
              const val: string = e.target.value;
              setTitle(val);
            }}
            onBlur={handleOnBlur}
            autoFocus
            onKeyPress={handleOnEnterPress}
          />
        )}
      </div> */}

      <Table className='my-event-table'>
        <TableHead>
          <TableRow>
            <TableCell className='met-h-cell' style={{ width: '44%' }}> Event Name </TableCell>
            <TableCell className='met-h-cell' style={{ width: '12%' }}> Status </TableCell>
            <TableCell className='met-h-cell' style={{ width: '10%' }}> Due Date </TableCell>
            <TableCell className='met-h-cell' style={{ width: '18%' }}> Building </TableCell>
            <TableCell className='met-h-cell' style={{ width: '16%', borderRight: 0 }}> Workspace </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {newEventOpen && (
            <TableRow>
              <TableCell className='met-b-cell' style={{ width: '44%', paddingLeft: 0 }}>
                <InputBase
                  value={title}
                  className='new-event-input'
                  placeholder='Write a Event Name'
                  style={{ width: '100%' }}
                  onChange={(e): void => {
                    const val: string = e.target.value;
                    setTitle(val);
                  }}
                  onBlur={handleOnBlur}
                  autoFocus
                  onKeyPress={handleOnEnterPress}
                />
              </TableCell>
            </TableRow>
          )}

          {events.map((event) => (
            <TableRow onClick={(): void => { handleRowClick(event); }} className='row-hover'>
              <TableCell className='met-b-cell' style={{ width: '44%' }}>
                <div className='event-name-cell'>
                  <p>
                    {' '}
                    {event.title}
                    {' '}
                  </p>
                </div>
              </TableCell>
              <TableCell className='met-b-cell' style={{ width: '12%' }}>
                <StatusTag value={event.status ? Number(event.status) : 1} />
              </TableCell>
              <TableCell className='met-b-cell' style={{ width: '12%' }}>
                {Boolean(event.due_date) && <span className={moment(event.due_date).isAfter(new Date()) ? 'normal' : 'passed'}>{event.due_date ? moment(event.due_date).format('MMM D') : event.due_date}</span>}
              </TableCell>

              <TableCell className='met-b-cell' style={{ width: '18%' }}>
                {event.building.address
                  ? (
                    <div className='building-cell'>
                      {
                        event.building.address
                      }
                    </div>
                  )
                  : null}

              </TableCell>
              <TableCell
                className='met-b-cell'
                style={{ width: '16%', borderRight: 0 }}
              >
                {event.workspace?.name
                  ? (
                    <div className='building-cell'>
                      <Avatar sx={{ bgcolor: '#00CFA1' }} variant='rounded'>
                        {event.workspace.name[0]}
                      </Avatar>
                      { event.workspace.name}
                    </div>
                  )
                  : null}
              </TableCell>
            </TableRow>

          ))}

        </TableBody>

      </Table>
      <div ref={ref} />
      {open && (
        <SideSheet
          open={open}
          handleClose={handleClose}

        />
      )}

    </div>
  );
}
