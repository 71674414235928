/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from '@mui/material';
import moment from 'moment';
import type { IPODetailsProps } from './types';

export default function PODetailDialog(props: IPODetailsProps): JSX.Element {
  const {
    open, handleClose, selectedData, getStatusValue,
  } = props;

  return (

    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
    >
      <DialogTitle id='alert-dialog-title'>
        <div onClick={handleClose} aria-hidden='true'>
          <i className='fas fa-times cursor-pointer' />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent='center' ml={-3} className='mt-4'>
          <Grid item sm={12} md={4}>
            <Grid container columnSpacing={2}>
              <Grid item sm={12} className='heading-PO'>
                <div className='PO-name'>
                  {selectedData?.event_name}
                </div>
                <div className='PO-status'>
                  {getStatusValue(selectedData?.status)}
                </div>
              </Grid>
              <Grid item sm={12} className='mt-4'>Vendor Information</Grid>
              <Grid item sm={12} md={4} className='PO-group'>
                <div className='PO-group-title'>PO Number</div>
                <div className='PO-group-description'>{selectedData?.po_number}</div>

              </Grid>
              <Grid item sm={12} md={4} className='PO-group'>
                <div className='PO-group-title'>Vendor</div>
                <div className='PO-group-description'>{ selectedData?.vendor.label }</div>

              </Grid>
              <Grid item sm={12} md={12} className='mb-4 mt-4 PO-group'>
                <div className='PO-group-title'>Vendor Info</div>
                <div className='PO-group-description'>{selectedData?.vendor_info}</div>

              </Grid>
              <Grid item sm={12} md={4} className='PO-group'>
                <div className='PO-group-title'>Expense Type</div>
                <div className='PO-group-description'>{selectedData?.expense_type.label}</div>

              </Grid>
              <Grid item sm={12} md={4} className='PO-group'>
                <div className='PO-group-title'>Payment Due</div>
                <div className='PO-group-description'>{selectedData?.Payment_due !== null && moment(selectedData?.Payment_due).format('MM/DD/YYYY')}</div>

              </Grid>
              <Grid item sm={12} md={4} className='PO-group'>
                <div className='PO-group-title'>Last Received</div>
                <div className='PO-group-description'>{selectedData?.last_received }</div>

              </Grid>
              <Grid item sm={12} className='PO-group'>
                <div className='PO-group-title'>Description</div>
                <div className='PO-group-description'>{selectedData?.description}</div>
              </Grid>

              <Grid item sm={12} md={4} className='PO-group'>
                <div className='PO-group-title'>Delivery Date</div>
                <div className='PO-group-description'>{selectedData?.delivery_date !== null && moment(selectedData?.delivery_date).format('MM/DD/YYYY')}</div>
              </Grid>
              <Grid item sm={12} md={4} className='PO-group'>
                <div className='PO-group-title'>Order Date</div>
                <div className='PO-group-description'>{selectedData?.order_date !== null && moment(selectedData?.order_date).format('MM/DD/YYYY')}</div>
              </Grid>
              <Grid item sm={12} md={4} className='PO-group'>
                <div className='PO-group-title'>Required by Date</div>
                <div className='PO-group-description'>{selectedData?.Required_by !== null && moment(selectedData?.Required_by).format('MM/DD/YYYY')}</div>
              </Grid>
              <Grid item sm={12} className='PO-group'>
                <div className='PO-group-title'>Total Amount</div>
                <div className='PO-group-description'>
                  $
                  {selectedData?.total}
                </div>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={2}>
          <div className='po-table-section'>
            <div className='mt-3 mb-2 d-flex justify-content-between'>
              <h6> Details  </h6>
            </div>
            <div className='po-table mb-4'>
              <div className='po-table-head'>
                <div className='po-head-description'>Descripton</div>
                <div className='po-head-account'>G/L Account</div>
                <div className='po-head-QTY'>QTY</div>
                <div className='po-head-price'>Unit Price</div>
                <div className='po-head-amount'>Amount</div>
              </div>
              {selectedData?.details.map((detail) => (
                <div className='po-table-input' key={detail?.description}>
                  <div className='po-body-description'>{detail?.description}</div>
                  <div className='po-body-account'>{ detail?.account?.label}</div>
                  <div className='po-body-QTY'>{detail?.quantity}</div>
                  <div className='po-body-price'>
                    $
                    {detail?.unit_price}
                  </div>
                  <div className='po-body-amount'>
                    $
                    {detail?.amount}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Grid>

        {/* {Array.isArray(selectedData?.yardi_response) && (
          <div className='yardi-response'>
            <div className='yardi-response-head'>Yardi Response:</div>
            {selectedData?.yardi_response.map((res) => (
              <div className='yardi-response-message'>
                {res.message}
              </div>
            ))}
          </div>
        )} */}
      </DialogContent>
    </Dialog>
  );
}
