/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from 'react';
import { IconButton, Input, InputAdornment } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PrimayButton from 'shared-components/components/PrimayButton';
import type { IHeader } from '../types';

function CustomMonthInput({ value, onClick, yearly }: { value: Date; onClick: () => void; yearly: boolean }): JSX.Element {
  return (
    <Input
      id='month-input'
      name='month-input'
      type='text'
      value={yearly ? moment(value).format('YYYY') : value}
      readOnly
      disableUnderline
      endAdornment={(
        <InputAdornment position='end'>
          <IconButton onClick={onClick}><ArrowDropDownIcon /></IconButton>
        </InputAdornment>
      )}
    />
  );
}

export default function Header(props: IHeader): JSX.Element {
  const {
    date: mainDate, setDate, yearly, setYearly, setNewEventOpen,
  } = props;

  return (
    <div>
      <p className='bc-header'> Budget Calendar </p>
      <div className='bc-bottom-header'>
        <DatePicker
          renderCustomHeader={({
            date,
            decreaseYear,
            increaseYear,
          }): JSX.Element => (
            <div className='DatePicker-custom-header'>
              <div className='DatePicker-custom-prev-button' onClick={decreaseYear} aria-hidden='true'>
                <i className='fas fa-caret-left' />
              </div>
              <div
                className='DatePicker-custom-date'
                onClick={(): void => {
                  setDate(date);
                  setYearly(true);
                }}
                aria-hidden='true'
              >
                {' '}
                {moment(date).format('YYYY')}

              </div>
              <div className='DatePicker-custom-next-button' onClick={increaseYear} aria-hidden='true'>
                <i className='fas fa-caret-right' />
              </div>
            </div>
          )}
          selected={mainDate}
          dateFormat='MMM yyyy'
          showMonthYearPicker
          className='monthpicker-input'
          calendarClassName='monthpicker-calendar'
          showPopperArrow={false}
          onChange={(obj: Date): void => { setDate(obj); setYearly(false); }}
          customInput={<CustomMonthInput value={mainDate} onClick={(): void => { }} yearly={yearly} />}
          // ref={this.startRef}
          shouldCloseOnSelect={false}
        />

        <div className='btn-wrapper'>
          <PrimayButton onClick={(): void => { setNewEventOpen(true); }}> Add Event </PrimayButton>
        </div>

      </div>

    </div>
  );
}
