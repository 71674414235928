import React from 'react';
import { Grid, Typography } from '@mui/material';
import './_accountSetting.scss';
import Switch from 'shared-components/inputs/Switch';

function Togglers(): JSX.Element {
  return (
    <div className='toggler-container'>
      <Typography className='notification-header'>
        Notifications
      </Typography>
      <Grid className='toggler-header'>
        <Grid item lg={6} className='header-1'>
          {' '}

          <Typography className='toggler-desc-1'>
            Email me when I have been mentioned!
          </Typography>

        </Grid>
        <Grid item lg={6} className='header-2'>
          <Switch name='notification' label='' />
        </Grid>
      </Grid>
    </div>
  );
}

export default Togglers;
