import React from 'react';
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';

interface Iprops {
  docURL: string | undefined;
}
export default function PinchZoom(props: Iprops): JSX.Element {
  const { docURL } = props;
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <PinchZoomPan>
        <img
          alt='img_'
          src={`${process.env.REACT_APP_IMAGE_URL}/api/${docURL}`}
        />
      </PinchZoomPan>
    </div>
  );
}
