import React from 'react';

export default function RFPScheduleContent(): JSX.Element {
  return (<div>RFPScheduleContact</div>
  );
}

// /* eslint-disable no-nested-ternary */
// /* eslint-disable @typescript-eslint/no-misused-promises */
// /* eslint-disable react/jsx-props-no-spreading */
// /* eslint-disable @typescript-eslint/no-unnecessary-condition */
// /* eslint-disable jsx-a11y/label-has-associated-control */
// import {
//   Button,
//   Grid, IconButton, InputBase, Tooltip,
// } from '@mui/material';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import * as yup from 'yup';
// import { yupResolver } from '@hookform/resolvers/yup';
// import { useForm } from 'react-hook-form';
// import AttachmentIcon from '@mui/icons-material/Attachment';
// import type { ChangeEvent } from 'react';
// import React, { useEffect, useState } from 'react';
// import { useMutation, useQuery, useQueryClient } from 'react-query';
// import type { AxiosResponse } from 'axios';
// import axios from 'axios';
// import { useParams, useNavigate } from 'react-router-dom';
// import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import Popover from '@mui/material/Popover';
// import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
// import { useSnackbar } from 'notistack';
// import HookTextField from 'shared-components/hooks/HookTextField';
// import HookSelectField from 'shared-components/hooks/HookSelectField';
// import HookCheckbox from 'shared-components/hooks/HookCheckbox';
// import PrimayButton from 'shared-components/components/PrimayButton';
// import CustomSwitch from 'shared-components/inputs/Switch';
// import StradaLoading from 'shared-components/components/StradaLoader';
// import { decrypt } from 'shared-components/hooks/useEncryption';
// import type { IFormValues } from 'formsTypes';
// import type { IEventScheduleModule } from 'admin/AdminFormTypes';
// import { WithContext as ReactTags } from 'react-tag-input';
// import SelectInput from 'shared-components/inputs/SelectInput';
// import makeStyles from '@mui/styles/makeStyles';
// import type { SelectChangeEvent } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import type { RootState } from 'mainStore';
// import { useSelector } from 'react-redux';
// import HookSelectWithSearchField from 'shared-components/hooks/HookSelectWithSearch';
// // import DateRangeDialog from './DateRangeDialog';
// import type {
//   IEventScheduleFile, IAddRFPData, IRFPData, ISchedualingData, IVendorRespose,
// } from '../types';
// // import CustomCalendar from './CustomCalendar';

// interface IStatusOption {
//   name: string;
//   value: number;
//   color: string;
//   background: string;
// }
// interface IDetail {
//   id: number;
//   template_name: string;
// }

// interface IRFPlist {
//   detail: IDetail[];
// }

// interface IVendor {
//   name: string;
//   label: string;
//   email: string;
//   value: number | string;
// }

// const tagList = [
//   {
//     name: 'Not Started',
//     value: 1,
//     color: 'rgba(33, 33, 33, 0.6)',
//     background: 'rgba(0, 0, 0, 0.08)',
//   },
//   {
//     name: 'In Process',
//     value: 2,
//     color: 'rgba(249, 168, 37, 0.87)',
//     background: 'rgba(249, 168, 37, 0.08)',
//   },
//   {
//     name: 'Scheduled',
//     value: 3,
//     color: 'rgba(33, 150, 243, 0.87)',
//     background: 'rgba(33, 150, 243, 0.08)',
//   },
//   {
//     name: 'Completed',
//     value: 4,
//     color: 'rgb(76, 175, 80)',
//     background: 'rgba(76, 175, 80, 0.08)',
//   },
//   {
//     name: 'As Needed',
//     value: 5,
//     color: 'rgb(0, 172, 193)',
//     background: 'rgba(0, 172, 193, 0.08)',
//   },
//   {
//     name: 'Contingency',
//     value: 6,
//     color: 'rgb(216, 27, 96)',
//     background: 'rgba(216, 27, 96, 0.08)',
//   },
//   {
//     name: 'Contract',
//     value: 7,
//     color: 'rgb(94, 53, 177)',
//     background: 'rgba(94, 53, 177, 0.08)',
//   },
// ];

// const scheduleContentDefaultValues = {
//   invitation_title: '',
//   vendor: NaN,
//   vendor_email: '',
//   subject: '',
//   description: '',
//   track_email: false,
//   is_email_scheduled: true,
//   is_rfp_form_link: false,
//   durationType: '',
//   dayRangeType: '',
//   startDate: '',
//   endDate: '',
//   timeDuration: null,
//   future: '',
//   range: '',
//   rfp_form: '',
//   forever: '',
//   status: 1,
//   files: [],

// };

// const useStyles = makeStyles(() => ({
//   sss: {
//     '& .MuiSelect-outlined': {
//       border: '1px solid rgb(226, 226, 225)',

//     },
//     '& .MuiOutlinedInput-notchedOutline': {
//       border: 'none',
//     },
//   },
// }));

// const rfpFormSchema = yup.object().shape({
//   // invitation_title: yup.string().required('Enter Title').nullable(),
// });

// export default function RFPScheduleContent(): JSX.Element {
//   const queryClient = useQueryClient();
//   const { enqueueSnackbar } = useSnackbar();
//   const classes = useStyles();
//   const navigate = useNavigate();
//   const {
//     buildingId, eventId, eventType,
//   } = useParams();
//   const currentWorkspace = useSelector(
//     (state: RootState) => state.workspaces.currentWorkspace.currentWorkspace,
//   );
//   const [vendorBcc, setVendorBcc] = useState<{ id: string; text: string }[]>([]);
//   const [cc, setcc] = useState<{ id: string; text: string }[]>([]);
//   const [ccError, setCCError] = useState<boolean>(false);
//   const [bccError, setBCCError] = useState<boolean>(false);
//   const [futureDays, setFutureDays] = useState<number | string | null>('');
//   const [rfpSelectionDisabled, setRFPSelectionDisabled] = useState<boolean>(true);
//   const [rfpFormData, setRFPFormData] = useState({
//     value: '',
//     name: '',
//   });
//   const [rfpNotExist, setRFPNotExist] = useState<boolean>(false);
//   const [isAddRFP, setIsAddRFP] = useState<boolean>(false);
//   const [editRFPData, setEditRFPData] = useState<IRFPData | null>(null);
//   const [selectedTag, setSelectedTag] = React.useState<IStatusOption | null>({
//     name: 'Not Started',
//     value: 1,
//     color: 'rgba(33, 33, 33, 0.6)',
//     background: 'rgba(0, 0, 0, 0.08)',
//   });

//   const handleDelete = (i: number): void => {
//     setVendorBcc([...vendorBcc.filter((tag, index) => index !== i)]);
//     setCCError(false);
//   };
//   const handleAddition = (vbcc: { id: string; text: string }): void => {
//     const validation = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
//     if (vbcc.text.trim() && validation.test(vbcc.text)) {
//       setVendorBcc([...vendorBcc, vbcc]);
//       setBCCError(false);
//     } else if (vbcc.text.trim() === '') {
//       setBCCError(false);
//     } else {
//       setBCCError(true);
//     }
//   };
//   const handleDeletecc = (i: number): void => {
//     setcc([...cc.filter((tag, index) => index !== i)]);
//     setCCError(false);
//   };
//   const handleAdditioncc = (vcc: { id: string; text: string }): void => {
//     const validation = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
//     if (vcc.text.trim() && validation.test(vcc.text)) {
//       setcc([...cc, vcc]);
//       setCCError(false);
//     } else if (vcc.text.trim() === '') {
//       setCCError(false);
//     } else {
//       setCCError(true);
//     }
//   };

//   const {
//     control, formState, handleSubmit, setValue, watch, getValues,
//   } = useForm<IFormValues>({
//     mode: 'onChange',
//     resolver: yupResolver(rfpFormSchema),
//     defaultValues: scheduleContentDefaultValues,
//   });

//   const { errors } = formState;
//   //  gettting current event schedule

//   const { data: schedulingData = null } = useQuery(
//     'get-event-schedule',
//     async () => axios({
//       url: `/api/budget-calendar/event-scheduling/${eventId}/?type=${eventType}`,
//       method: 'GET',
//     }),
//     {
//       select: (res: AxiosResponse<ISchedualingData>) => res.data,
//     },
//   );

//   const { mutate: handlePost, isLoading: updating } = useMutation(async (dataToPost: IEventScheduleModule) => axios({
//     // url: `/api/budget-calendar/event-scheduling/${eventId}/?type=${eventType}&class=${kind}`,
//     url: `/api/budget-calendar/event-scheduling/${eventId}/?type=${eventType}`,
//     method: 'PATCH',
//     data: dataToPost,
//   }), {
//     onSuccess: async (): Promise<void> => {
//       await queryClient.invalidateQueries('get-event-schedule').then();
//       enqueueSnackbar(`RFP was sent for "${schedulingData?.title}"`);
//       navigate(-1);
//     },
//     onError: () => {
//       enqueueSnackbar('Can not send email', { variant: 'error' });
//     },
//   });

//   const { data: vendorList } = useQuery(
//     'event-schedule-vendor',
//     async () => axios({
//       url: '/api/vendor-contact/',
//       params: {
//         property_id: Number(decrypt(buildingId)),
//       },
//     }),
//     {
//       select: (res: AxiosResponse<IVendorRespose>) => res.data.result.map((vndr) => ({
//         name: `${vndr.company} (${vndr.name} ${vndr.surname})`,
//         label: `${vndr.company} (${vndr.name} ${vndr.surname})`,
//         email: vndr.email,
//         value: vndr.id,
//       })),
//     },
//   );

//   const { data: rfpList } = useQuery(
//     'event-get-rfp',
//     async () => axios({
//       url: `/api/rfp-template/?workspace=${currentWorkspace.id}`,
//     }),
//     {
//       select: (res: AxiosResponse<IRFPlist>) => res.data.detail.map((rfp) => ({
//         name: rfp.template_name,
//         value: `${rfp.id}`,
//       })),
//     },
//   );

//   const handleRadioChange = (e: ChangeEvent<HTMLInputElement>): void => {
//     const { value } = e.target;
//     if (value === 'future') {
//       setValue('range', '', { shouldDirty: true });
//       setValue('forever', '', { shouldDirty: true });
//     } else if (value === 'range') {
//       setValue('startDate', '', { shouldDirty: true });
//       setValue('endDate', '', { shouldDirty: true });
//     }
//     setValue('dayRangeType', value);
//   };

//   const handleRadioForDuration = (e: ChangeEvent<HTMLInputElement>): void => {
//     const { value } = e.target;
//     if (value !== '00') {
//       setValue('timeDuration', 0, { shouldDirty: true });
//     } else {
//       const timeDuration = Number.parseInt(value, 10);
//       setValue('timeDuration', Number(timeDuration));
//     }
//     setValue('durationType', value);
//   };

//   useEffect(() => {
//     const currentStatus = tagList.filter((tag) => tag.value === watch('status'));
//     setSelectedTag(currentStatus[0]);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [watch('status')]);

//   const onTimeDurationChange = (e: ChangeEvent<HTMLInputElement>): void => {
//     if (Number(e.target.value)) {
//       setValue('timeDuration', Number(e.target.value));
//     }
//   };
//   //   useEffect(() => {
//   //     const selectedVendor = getValues('vendor');
//   //     console.log(selectedVendor);
//   //     // eslint-disable-next-line use-isnan
//   //     if (vendorList !== undefined && vendorList.length > 0 && kind !== 'RFP' && selectedVendor !== NaN) {
//   //       setValue('vendor_email', vendorList.filter((vendor) => vendor.value === selectedVendor)[0]?.email);
//   //     }
//   //     // eslint-disable-next-line react-hooks/exhaustive-deps
//   //   }, [watch('vendor')]);
//   const { mutate: uploadFile, isLoading: uploading } = useMutation(
//     async ({ file, id }: { file: File; id: string | undefined }) => {
//       const formData = new FormData();
//       formData.append('file', file);
//       formData.append('filename', file.name);
//       formData.append('event', String(id));
//       formData.append('type', String(eventType));
//       formData.append('group', 'rfp');
//       return axios({
//         url: '/api/budget-calendar/files/',
//         method: 'POST',
//         data: formData,
//       });
//     },
//     {
//       onSuccess: (res: AxiosResponse<IEventScheduleFile>): void => {
//         // await queryClient.invalidateQueries('get-event-schedule').then();
//         const oldFiles = watch('files');
//         const {
//           // eslint-disable-next-line @typescript-eslint/naming-convention
//           created_at, event, file, filename, id, type,
//         } = res.data;

//         setValue('files', [...oldFiles, {
//           created_at, event, file, filename, id, type,
//         }]);
//         // await queryClient.invalidateQueries('get-single-sidesheet').then();
//       },
//     },
//   );

//   const { mutate: deleteFile, isLoading: deleting } = useMutation(
//     async (fileId: number) => axios({
//       url: `/api/budget-calendar/files/${fileId}/`,
//       method: 'DELETE',
//     }),
//     {
//       onSuccess: (res, fileId): void => {
//         const newFiles = watch('files').filter((file) => file.id !== fileId);
//         setValue('files', newFiles, { shouldDirty: true });
//       },
//     },
//   );

//   const handleAttachmentChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
//     if (e.target.files !== null && e.target.files.length !== 0) {
//       let id: string | undefined = '';
//       id = String(editRFPData?.id);
//       uploadFile({ file: e.target.files[0], id });
//     }
//   };

//   useEffect(() => {
//     if (schedulingData !== null) {
//       // eslint-disable-next-line @typescript-eslint/no-unused-expressions
//       getValues('invitation_title') === '' && setValue('invitation_title', schedulingData.invitation_title, { shouldDirty: true });
//       // setValue('vendor', Number(schedulingData.vendor.VendorId), { shouldDirty: true });
//       setValue('vendor_email', schedulingData.vendor_email, { shouldDirty: true });
//       setValue('subject', schedulingData.subject, { shouldDirty: true });
//       setValue('description', schedulingData.description, { shouldDirty: true });
//       setValue('dayRangeType', schedulingData.dayRangeType, { shouldDirty: true });
//       setValue('durationType', schedulingData.durationType, { shouldDirty: true });
//       setValue('endDate', schedulingData.endDate, { shouldDirty: true });
//       setValue('startDate', schedulingData.startDate, { shouldDirty: true });
//       setValue('timeDuration', schedulingData.timeDuration, { shouldDirty: true });
//       setValue('files', schedulingData.files, { shouldDirty: true });
//       setValue('status', schedulingData.status, { shouldDirty: true });

//       if (getValues('is_rfp_form_link') === schedulingData.is_rfp_form_link) {
//         setValue('is_rfp_form_link', schedulingData.is_rfp_form_link, { shouldDirty: true });
//       }
//       setFutureDays(schedulingData.future_days);
//       // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
//       if (schedulingData.vendor !== null) {
//         setValue('vendor', Number(schedulingData.vendor), { shouldDirty: true });
//       }
//       if (schedulingData.rfp_form !== null) {
//         setValue('rfp_form', schedulingData.rfp_form, { shouldDirty: true });
//       }

//       // eslint-disable-next-line array-callback-return
//       schedulingData.vendor_cc.map((item, i) => { setcc([...cc, { id: JSON.stringify(i), text: item }]); });
//       // eslint-disable-next-line array-callback-return
//       schedulingData.vendor_bcc.map((item, i) => { setVendorBcc([...vendorBcc, { id: JSON.stringify(i), text: item }]); });
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [getValues, schedulingData, setValue]);

//   useEffect(() => {
//     if (rfpFormData.name === '') {
//       if (rfpList !== undefined && rfpList.length > 0 && schedulingData !== null && schedulingData.rfp_form !== null && schedulingData.rfp_form !== '') {
//         const newOptions = rfpList.filter((option) => option.name === schedulingData.rfp_form);
//         if (newOptions.length > 0) {
//           setRFPFormData(newOptions[0]);
//         } else {
//           setRFPFormData({ value: schedulingData.rfp_form, name: schedulingData.rfp_form });
//           setRFPNotExist(true);
//         }
//       } else {
//         setRFPFormData({ value: '', name: '' });
//       }
//     }
//   }, [rfpFormData.name, rfpList, schedulingData]);

//   const handleRFPChange = (e: SelectChangeEvent): void => {
//     if (rfpList !== undefined && rfpList.length > 0) {
//       const newOptions = rfpList.filter((option) => option.value === e.target.value);
//       if (newOptions.length > 0) {
//         setRFPFormData(newOptions[0]);
//       } else {
//         setRFPFormData({ value: '', name: '' });
//       }
//       setRFPSelectionDisabled(false);
//     }
//   };

//   useEffect(() => {
//     console.log({ schedulingData });
//   }, [schedulingData]);

//   const onSubmit = (formData: IEventScheduleModule): void => {
//     if (schedulingData !== null) {
//       schedulingData.future_days = Number(futureDays);
//       handlePost({
//         ...schedulingData, ...formData, rfp_form: rfpFormData.name, rfp_form_id: Number(rfpFormData.value), vendor_cc: cc.map((singleCC) => singleCC.text), vendor_bcc: vendorBcc.map((singleCC) => singleCC.text),
//       });
//     }
//   };

//   useEffect(() => {
//     document.getElementById('budget-calendar-start')?.scrollIntoView();
//   }, []);

//   const clearFieldsData = (): void => {
//     setValue('description', '', { shouldDirty: true });
//     setValue('subject', '', { shouldDirty: true });
//     setValue('vendor_email', '', { shouldDirty: true });
//     setValue('vendor', '', { shouldDirty: true });
//     setVendorBcc([]);
//     setcc([]);
//   };

//   const { mutate: addRFPMessage } = useMutation(async (dataToPost: IAddRFPData) => axios({
//     url: '/api/budget-calendar/rfp/',
//     method: 'POST',
//     data: dataToPost,
//   }), {
//     onSuccess: (res: AxiosResponse<IRFPData>) => {
//       setEditRFPData(res.data);
//     },
//     onError: () => {
//       enqueueSnackbar('Can not add message');
//     },
//   });

//   const { mutate: updateRFPMessage } = useMutation(async ({ id, data }: { id: number | undefined; data: IRFPData }) => axios({
//     url: `/api/budget-calendar/rfp/${id}/`,
//     method: 'PATCH',
//     data,
//   }), {
//     onSuccess: async (): Promise<void> => {
//       setEditRFPData(null);
//       setIsAddRFP(false);
//       clearFieldsData();
//       await queryClient.invalidateQueries('get-event-schedule').then();
//     },
//     onError: () => {
//       enqueueSnackbar('Can not delete message');
//     },
//   });

//   const { mutate: deleteRFPMessage } = useMutation(async (id: number | undefined) => axios({
//     url: `/api/budget-calendar/rfp/${id}/`,
//     method: 'DELETE',
//   }), {
//     onSuccess: async (): Promise<void> => {
//       await queryClient.invalidateQueries('get-event-schedule').then();
//     },
//     onError: () => {
//       enqueueSnackbar('Can not delete message');
//     },
//   });

//   const handleRFPEdit = (index: number): void => {
//     if (schedulingData) {
//       const data = schedulingData.RFP[index];
//       setEditRFPData(schedulingData.RFP[index]);
//       setValue('description', data.description, { shouldDirty: true });
//       setValue('subject', data.subject, { shouldDirty: true });
//       setValue('vendor_email', data.vendor_email, { shouldDirty: true });
//       setValue('vendor', data.vendor.value, { shouldDirty: true });
//       const bcc = data.vendor_bcc.map((val: string) => ({ text: val, id: val }));
//       const ccc = data.vendor_cc.map((cc_val: string) => ({ text: cc_val, id: cc_val }));
//       setVendorBcc(bcc);
//       setcc(ccc);
//     }
//   };

//   const handleRFPDelete = (index: number): void => {
//     // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
//     if (schedulingData && schedulingData.RFP[index].id !== undefined) {
//       deleteRFPMessage(schedulingData.RFP[index].id);
//     }
//   };

//   const renderDisplayRFP = (rfp: IRFPData, index: number): JSX.Element => (
//     <Grid item className='message-card'>
//       <div className='message-email'>
//         {rfp.vendor.label}
//         ,
//         {' '}
//         {rfp.vendor_email}
//         <div className='message-options'>
//           <EditIcon className='cursor-pointer' onClick={(): void => { handleRFPEdit(index); }} />
//           <span style={{ marginLeft: '5px' }}>
//             <DeleteIcon className='cursor-pointer' onClick={(): void => { handleRFPDelete(index); }} />
//           </span>
//         </div>
//       </div>
//       <div className='message-subject'>{rfp.subject}</div>
//       <div className='message-description'>{rfp.description}</div>
//       <div className='message-attachment-list' style={{ marginTop: '15px' }}>
//         {rfp.files?.map((file) => (
//           <Tooltip title={file.filename}>
//             <div className='message-attachment' style={{ marginTop: '0px' }}>
//               <span style={{ paddingRight: '6px', display: 'flex' }}><InsertDriveFileIcon fontSize='small' /></span>
//               {file.filename !== '' && file.filename.length > 10 ? `${file.filename.substring(0, 10)}...` : file.filename}
//               <span style={{ display: 'flex' }}>
//                 <ArrowDropDownIcon fontSize='small' />
//               </span>
//             </div>
//           </Tooltip>
//         ))}
//       </div>

//     </Grid>
//   );

//   const onAddRFPClick = (): void => {
//     setIsAddRFP(true);
//     addRFPMessage({ type: 'event', event: Number(eventId) });
//   };

//   const onCancelRFPClick = (): void => {
//     if (isAddRFP && editRFPData?.is_saved === false) {
//       deleteRFPMessage(editRFPData.id);
//     }
//     setEditRFPData(null);
//     clearFieldsData();
//     setIsAddRFP(false);
//   };

//   const onSaveRFPClick = (): void => {
//     const fileData = getValues('files');
//     let vendorData: IVendor[] = [];
//     if (vendorList !== undefined) {
//       vendorData = vendorList.filter((option) => Number(option.value) === getValues('vendor'));
//     }
//     const data: IRFPData = {
//       description: getValues('description'),
//       event: Number(eventId),
//       files: fileData,
//       is_saved: true,
//       subject: getValues('subject'),
//       type: 'event',
//       vendor: vendorData[0],
//       vendor_cc: cc.map((singleCC) => singleCC.text),
//       vendor_bcc: vendorBcc.map((singleCC) => singleCC.text),
//       vendor_email: getValues('vendor_email'),
//     };
//       // if (isAddRFP) {
//       //   addRFPMessage(data);
//       // } else {
//     updateRFPMessage({ id: editRFPData?.id, data });
//     // }
//   };

//   const getDisabledCheck = (): boolean => {
//     watch('vendor_email');
//     watch('vendor');
//     watch('subject');
//     watch('description');

//     if (getValues('vendor') === '' || Number.isNaN(getValues('vendor')) || getValues('vendor_email') === '' || getValues('subject') === '' || getValues('subject') === null || getValues('description') === '' || getValues('description') === null) {
//       return true;
//     }
//     return false;
//   };

//   const renderAddEditForm = (): JSX.Element => (
//     <Grid container className='mt-4'>
//       <Grid container columnSpacing={2} className='d-flex mb-2 justify content-between'>
//         <Grid item sm={6}>
//           <div className='d-flex'>
//             {editRFPData === null ? 'Add ' : 'Edit '}
//             message
//             <label htmlFor='schedule-attachment-btn' className='attachment-btn ms-3'>
//               <AttachmentIcon color='primary' />
//               <h6> Attach File </h6>
//             </label>
//             <input id='schedule-attachment-btn' type='file' onChange={(e): void => { handleAttachmentChange(e); }} style={{ display: 'none' }} />
//           </div>
//         </Grid>
//         <Grid item sm={6} className='text-end'>
//           <Button
//             variant='text'
//             className='text-transform-none'
//             onClick={(): void => { onCancelRFPClick(); }}
//           >
//             Cancel
//           </Button>
//           <Button
//             variant='text'
//             disabled={getDisabledCheck()}
//             className='ms-2 text-transform-none'
//             onClick={(): void => { onSaveRFPClick(); }}
//           >
//             Save
//           </Button>
//         </Grid>
//       </Grid>
//       <Grid container columnSpacing={2}>
//         <Grid item md={6} sm={12}>
//           <HookSelectField
//             name='vendor'
//             label='Vendor'
//             options={vendorList ?? []}
//             control={control}
//             errors={errors}
//           />
//         </Grid>
//         <Grid item md={6} sm={12}>
//           <HookTextField
//             name='vendor_email'
//             label='Vendor Email'
//             control={control}
//             errors={errors}
//           />
//         </Grid>
//         <Grid item md={12}>
//           <HookTextField
//             name='subject'
//             label='Subject'
//             control={control}
//             errors={errors}
//           />
//         </Grid>

//         <Grid item md={12}>
//           <div className='bcc-wrapper'>
//             <label> CC </label>
//             <ReactTags
//               tags={cc}
//               handleDelete={handleDeletecc}
//               handleAddition={(tagone): void => { handleAdditioncc(tagone); }}
//               // autofocus
//               handleInputBlur={(tagVal): void => { handleAdditioncc({ id: tagVal, text: tagVal }); }}
//             />
//           </div>
//           <label className='error-message' style={{ marginBottom: '24px' }}>
//             {' '}
//             {ccError ? 'Enter a valid email' : ''}
//             {' '}
//           </label>
//         </Grid>

//         <Grid item md={12}>
//           <div className='bcc-wrapper'>
//             <label> BCC </label>
//             <ReactTags
//               tags={vendorBcc}
//               handleDelete={handleDelete}
//               handleAddition={(tagone): void => { handleAddition(tagone); }}
//               // autofocus
//               handleInputBlur={(tagVal): void => { handleAddition({ id: tagVal, text: tagVal }); }}
//             />
//           </div>
//           <label className='error-message' style={{ marginBottom: '24px' }}>
//             {' '}
//             {bccError ? 'Enter a valid email' : ''}
//             {' '}
//           </label>
//         </Grid>

//         <Grid item md={12}>
//           <HookTextField
//             name='description'
//             label='Description'
//             control={control}
//             errors={errors}
//             multiline
//             rows={5}
//           />
//         </Grid>
//         <div className='message-attachment-list' style={{ marginTop: '15px' }}>
//           {watch('files').length > 0 && watch('files').map((file) => (

//             <PopupState variant='popover' popupId='demo-popup-popover'>
//               {(popupState): JSX.Element => (
//                 <div>
//                   <Tooltip title={file.filename}>
//                     <div className='message-attachment' style={{ marginTop: '0px' }} {...bindTrigger(popupState)}>
//                       {' '}
//                       <span style={{ paddingRight: '6px', display: 'flex' }}><InsertDriveFileIcon fontSize='small' /></span>
//                       {' '}
//                       {file.filename && file.filename.length > 10 ? `${file.filename.substring(0, 10)}...` : file.filename}
//                       {' '}
//                       <span style={{ display: 'flex' }}>
//                         {' '}
//                         <ArrowDropDownIcon fontSize='small' />
//                       </span>
//                     </div>
//                   </Tooltip>
//                   <Popover
//                     {...bindPopover(popupState)}
//                     anchorOrigin={{
//                       vertical: 'bottom',
//                       horizontal: 'center',
//                     }}
//                     transformOrigin={{
//                       vertical: 'top',
//                       horizontal: 'center',
//                     }}
//                   >
//                     <div className='event-schedule-att-popover'>
//                       <div> Download </div>
//                       <div onClick={(): void => { deleteFile(file.id); popupState.close(); }} aria-hidden='true'> Delete </div>
//                     </div>
//                   </Popover>
//                 </div>
//               )}
//             </PopupState>

//           ))}
//         </div>

//       </Grid>
//     </Grid>
//   );

//   const renderRFPContent = (): JSX.Element => {
//     // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
//     const count = schedulingData && schedulingData.RFP && schedulingData.RFP.filter((rfp: IRFPData) => !rfp.is_saved).length;
//     return (
//       <Grid container columnSpacing={2} className='d-flex justify content-between'>
//         <Grid item sm={6} className='message'>Messages</Grid>
//         {count === 0 && schedulingData && schedulingData.RFP.length === 0
//           ? (
//             <Grid item sm={6} className='text-right'>
//               <Button variant='text' onClick={(): void => { onAddRFPClick(); }}>
//                 <AddIcon fontSize='small' />
//                 Add
//               </Button>
//             </Grid>
//           )
//           : <Grid item sm={6} /> }

//         {count === 0 && !isAddRFP && schedulingData && schedulingData.RFP.length === 0
//           ? (
//             <Grid item sm={12} className='message-empty mt-4'>
//               Click on the button &#34;Add&#34; to write the messages to vendors.
//             </Grid>
//           ) : null }
//         {schedulingData && schedulingData.RFP.length > 0 && schedulingData.RFP.map((rfp, index) => {
//           if (editRFPData?.id === rfp.id) {
//             return renderAddEditForm();
//           }
//           return renderDisplayRFP(rfp, index);
//         })}

//         {isAddRFP && renderAddEditForm()}

//         {count === 0 && !isAddRFP && schedulingData && schedulingData.RFP.length > 0
//             && (
//               <Button variant='text' onClick={(): void => { onAddRFPClick(); }}>
//                 <AddIcon fontSize='small' />
//                 Add
//               </Button>
//             )}
//       </Grid>
//     );
//   };

//   return (
//     <div style={{ width: '100%', paddingTop: '62px' }} id='budget-calendar-start'>
//       <StradaLoading message={deleting ? 'Deleting...' : 'loading...'} open={uploading || deleting || updating} />
//       <Grid container>
//         <Grid item md={2} />
//         <Grid item md={8}>
//           <div className='event-schule-main-wrapper'>
//             <div className='header'>
//               <IconButton onClick={(): void => { navigate(`/building/budget-calendar/${buildingId}`); }} className='back-btn'>
//                 <ArrowBackIcon htmlColor='' />
//               </IconButton>
//               <h2>
//                 {' '}
//                 {schedulingData?.title}
//                 {' '}
//               </h2>

//               {selectedTag !== null ? (
//                 <div
//                   style={{
//                     background: selectedTag.background,
//                     color: selectedTag.color,
//                   }}
//                   className='single-tag'
//                 >
//                   {' '}
//                   {selectedTag.name}
//                 </div>
//               )
//                 : '-'}
//             </div>

//             {/* Form Area */}
//             <form onSubmit={handleSubmit(onSubmit)}>
//               {watch('is_email_scheduled') && (
//                 <HookTextField
//                   name='invitation_title'
//                   label='Title for Calendar Invite'
//                   control={control}
//                   errors={errors}
//                 />
//               )}

//               <div className='custom-switch'>
//                 <CustomSwitch
//                   checked={watch('is_email_scheduled')}
//                   onChange={(e, val): void => {
//                     setValue('is_email_scheduled', val, { shouldDirty: true });
//                   }}
//                 />
//                 <p> Add scheduling link to email </p>
//               </div>

//               {watch('is_email_scheduled') && (
//                 <div>

//                   <div className='date-range-section'>
//                     <h6> Date Range </h6>
//                     <p> Set a range of dates when you can accept meetings. </p>

//                     <div className='input-section'>
//                       <FormControl>
//                         <RadioGroup
//                           aria-labelledby='demo-radio-buttons-group-label'
//                           defaultValue=''
//                           name='dayRangeType'
//                           value={watch('dayRangeType')}
//                           onChange={handleRadioChange}
//                           sx={{
//                             '& .MuiSvgIcon-root': {
//                               fontSize: 24,
//                             },
//                           }}
//                         >
//                           <FormControlLabel
//                             value='forever'
//                             className='form-control-label-schedule'
//                             control={<Radio />}
//                             label={(
//                               <div className='date-range-wrapper'>
//                                 <span>Indefinitely into the future</span>
//                               </div>
//                             )}
//                           />
//                         </RadioGroup>
//                       </FormControl>
//                     </div>
//                     <h6> Event Duration </h6>
//                     <p> Choose a duration </p>

//                     <div className='event-duration-section'>
//                       <FormControl>
//                         <RadioGroup
//                           aria-labelledby='demo-radio-buttons-group-label'
//                           defaultValue=''
//                           name='durationType'
//                           value={watch('durationType')}
//                           onChange={handleRadioForDuration}
//                           sx={{
//                             '& .MuiSvgIcon-root': {
//                               fontSize: 24,
//                             },
//                           }}
//                         >
//                           <div className='fixed-durations'>
//                             <FormControlLabel
//                               value='15'
//                               control={<Radio color='primary' />}
//                               label='15 min'
//                               classes={{
//                                 label: 'duration-radio-label',
//                               }}
//                             />
//                             <FormControlLabel
//                               value='30'
//                               control={<Radio />}
//                               label='30 min'
//                               classes={{
//                                 label: 'duration-radio-label',
//                               }}
//                             />
//                             <FormControlLabel
//                               value='45'
//                               control={<Radio />}
//                               label='45 min'
//                               classes={{
//                                 label: 'duration-radio-label',
//                               }}
//                             />
//                             <FormControlLabel
//                               value='60'
//                               control={<Radio />}
//                               label='60 min'
//                               classes={{
//                                 label: 'duration-radio-label',
//                               }}
//                             />
//                           </div>
//                           <p> ..or choose a custom duration </p>
//                           <FormControlLabel
//                             value='00'
//                             control={<Radio />}
//                             label={(
//                               <div className='custom-duration-input'>
//                                 <div className='input-wrapper'>
//                                   <InputBase
//                                     name='custom-duration'
//                                     type='number'
//                                     value={watch('timeDuration')}
//                                     disabled={watch('durationType') !== '00'}
//                                     onChange={onTimeDurationChange}
//                                   />
//                                   <span>min</span>
//                                 </div>
//                               </div>
//                             )}
//                           />
//                         </RadioGroup>
//                       </FormControl>
//                     </div>
//                   </div>

//                   {/* Table Section  */}

//                   {/* <Grid item md={12}>
//                     <CustomCalendar
//                       schedulingData={schedulingData}
//                     />
//                   </Grid> */}

//                 </div>
//               )}

//               {(eventType !== 'task') && (
//                 <Grid container>
//                   <Grid item sm={6}>
//                     <div className='custom-switch'>
//                       <CustomSwitch
//                         checked={!!watch('is_rfp_form_link')}
//                         onChange={(e, val): void => {
//                           setValue('is_rfp_form_link', val);
//                         }}
//                       />
//                       <p>Add RFP form link to email</p>
//                     </div>
//                   </Grid>
//                   <Grid item sm={6}>
//                     {rfpNotExist ? (
//                       <div className='no-rfp'>
//                         {rfpFormData.name}
//                         <ArrowDropDownIcon />
//                       </div>
//                     )
//                       : (
//                         <SelectInput
//                           name='country'
//                           label={rfpFormData.value === '' ? 'Select RFP form' : ''}
//                           value={rfpFormData.value}
//                           showPleaseSelect={false}
//                           className={classes.sss}
//                           showPlaceholder
//                           onChange={(e): void => { handleRFPChange(e); }}
//                           options={rfpList ?? []}
//                           disabled={!!((rfpFormData.name !== '' && rfpSelectionDisabled) || !watch('is_rfp_form_link'))}
//                         />
//                       ) }
//                   </Grid>
//                 </Grid>
//               )}

//               { renderRFPContent() }

//               {/* show below only when kind is scheduling */}
//               <Grid container columnSpacing={2}>
//                 <Grid item md={6} sm={12}>
//                   <HookSelectWithSearchField
//                     name='vendor'
//                     label='Vendor'
//                     options={vendorList ?? []}
//                     control={control}
//                     errors={errors}
//                   />
//                 </Grid>
//                 <Grid item md={6} sm={12}>
//                   <HookTextField
//                     name='vendor_email'
//                     label='Vendor Email'
//                     control={control}
//                     errors={errors}
//                   />
//                 </Grid>
//                 <Grid item md={12}>
//                   <HookTextField
//                     name='subject'
//                     label='Subject'
//                     control={control}
//                     errors={errors}
//                   />
//                 </Grid>

//                 <Grid item md={12}>
//                   <div className='bcc-wrapper'>
//                     <label> CC </label>
//                     <ReactTags
//                       tags={cc}
//                       handleDelete={handleDeletecc}
//                       handleAddition={(tagone): void => { handleAdditioncc(tagone); }}
//                       placeholder=' '
//                       // autofocus
//                       handleInputBlur={(tagVal): void => { handleAdditioncc({ id: tagVal, text: tagVal }); }}
//                     />
//                   </div>
//                   <label className='error-message' style={{ marginBottom: '24px' }}>
//                     {' '}
//                     {ccError ? 'Enter a valid email' : ''}
//                     {' '}
//                   </label>
//                 </Grid>

//                 <Grid item md={12}>
//                   <div className='bcc-wrapper'>
//                     <label> BCC </label>
//                     <ReactTags
//                       tags={vendorBcc}
//                       handleDelete={handleDelete}
//                       handleAddition={(tagone): void => { handleAddition(tagone); }}
//                       placeholder=''
//                       // autofocus
//                       handleInputBlur={(tagVal): void => { handleAddition({ id: tagVal, text: tagVal }); }}
//                     />
//                   </div>
//                   <label className='error-message' style={{ marginBottom: '24px' }}>
//                     {' '}
//                     {bccError ? 'Enter a valid email' : ''}
//                     {' '}
//                   </label>
//                 </Grid>

//                 <Grid item md={12}>
//                   <HookTextField
//                     name='description'
//                     label='Description'
//                     control={control}
//                     errors={errors}
//                     multiline
//                     rows={5}
//                   />
//                 </Grid>
//                 <Grid item md={12}>
//                   <label htmlFor='schedule-attachment-btn' className='attachment-btn'>
//                     <AttachmentIcon color='primary' />
//                     <h6> Attach File </h6>
//                   </label>
//                   <input id='schedule-attachment-btn' type='file' onChange={(e): void => { handleAttachmentChange(e, null); }} style={{ display: 'none' }} />
//                 </Grid>
//                 <div className='message-attachment-list' style={{ marginTop: '15px' }}>
//                   {watch('files').length > 0 && watch('files').map((file) => (

//                     <PopupState variant='popover' popupId='demo-popup-popover'>
//                       {(popupState): JSX.Element => (
//                         <div>
//                           <Tooltip title={file.filename}>
//                             <div className='message-attachment' style={{ marginTop: '0px' }} {...bindTrigger(popupState)}>
//                               {' '}
//                               <span style={{ paddingRight: '6px', display: 'flex' }}><InsertDriveFileIcon fontSize='small' /></span>
//                               {' '}
//                               {file.filename && file.filename.length > 10 ? `${file.filename.substring(0, 10)}...` : file.filename}
//                               {' '}
//                               <span style={{ display: 'flex' }}>
//                                 {' '}
//                                 <ArrowDropDownIcon fontSize='small' />
//                               </span>
//                             </div>
//                           </Tooltip>
//                           <Popover
//                             {...bindPopover(popupState)}
//                             anchorOrigin={{
//                               vertical: 'bottom',
//                               horizontal: 'center',
//                             }}
//                             transformOrigin={{
//                               vertical: 'top',
//                               horizontal: 'center',
//                             }}
//                           >
//                             <div className='event-schedule-att-popover'>
//                               <div> Download </div>
//                               <div onClick={(): void => { deleteFile(file.id); popupState.close(); }} aria-hidden='true'> Delete </div>
//                             </div>
//                           </Popover>
//                         </div>
//                       )}
//                     </PopupState>

//                   ))}
//                 </div>

//               </Grid>

//               <Grid item md={12}>
//                 <div className='event-schedule-footer'>
//                   <HookCheckbox
//                     name='track_email'
//                     label='Track Emails'
//                     control={control}
//                   />
//                   <div className='btn-wrapper'>
//                     <PrimayButton type='submit' disabled={schedulingData?.RFP.length === 0}>
//                       Send RFP
//                     </PrimayButton>
//                   </div>
//                 </div>
//               </Grid>
//             </form>
//           </div>
//         </Grid>
//         <Grid item md={2} />
//       </Grid>
//     </div>
//   );
// }
