import {
  BudgetCalendarIcon, CIOIcon, PurhcaseOrderIcon, ChecklistIcon,
} from './icons';
import type { INavbarItem } from '../types';

export const mainNavbarConfig: INavbarItem[] = [
  {
    id: '01-budget-calendar',
    name: 'budget-calendar',
    heading: 'Budget Calendar',
    hasChildren: false,
    children: null,
    hasIcon: true,
    icon: BudgetCalendarIcon,
    route: '/building/budget-calendar',
    parentName: null,
  },
  {
    id: '02-cois',
    name: 'cois',
    heading: 'COIs',
    hasChildren: false,
    children: null,
    hasIcon: true,
    icon: CIOIcon,
    route: '/building/cois',
    parentName: null,
  },
  {
    id: '03-rfps',
    name: 'rfps',
    heading: 'RFPs',
    hasChildren: false,
    children: null,
    hasIcon: true,
    icon: CIOIcon,
    route: '/building/rfps',
    parentName: null,
  },
  {
    id: '04-checklist',
    name: 'checklists',
    heading: 'Checklists',
    hasChildren: false,
    children: null,
    hasIcon: true,
    icon: ChecklistIcon,
    route: '/building/checklists',
    parentName: null,
  },
  // {
  //   id: '05-unpaid-charges',
  //   name: 'unpaid-charges',
  //   heading: 'Unpaid Charges',
  //   hasChildren: false,
  //   children: null,
  //   hasIcon: true,
  //   icon: UnpaidChargesIcon,
  //   route: '/building/unpaid-charges',
  //   parentName: null,
  // },
  // {
  //   id: '06-invoicing',
  //   name: 'invoicing',
  //   heading: 'Invoicing',
  //   hasChildren: false,
  //   children: null,
  //   hasIcon: true,
  //   icon: InvoicingIcon,
  //   route: '/invoicing',
  //   parentName: null,
  // },
  {
    id: '07-purchase-order',
    name: 'purchase-order',
    heading: 'Purchase Order',
    hasChildren: false,
    children: null,
    hasIcon: true,
    icon: PurhcaseOrderIcon,
    parentName: null,
    route: '/building/purchase-orders',
  },
  // {
  //   id: '08-building-settings',
  //   name: 'building-settings',
  //   heading: 'Building Settings',
  //   hasChildren: false,
  //   children: null,
  //   hasIcon: true,
  //   icon: BuildingSettings,
  //   route: '/workspace/settings/details',
  //   parentName: null,
  // },

];

export const settingNavbarConfig: INavbarItem[] = [
  {
    id: '01-details',
    name: 'details',
    heading: 'Details',
    hasChildren: false,
    children: null,
    hasIcon: false,
    icon: undefined,
    route: '/workspace/settings/details',
    parentName: null,
  },
  {
    id: '01-buildings',
    name: 'buildings',
    heading: 'Buildings',
    hasChildren: false,
    children: null,
    hasIcon: false,
    icon: undefined,
    route: '/workspace/settings/buildings',
    parentName: null,
  },
  // {
  //   id: '01-details',
  //   name: 'details',
  //   heading: 'Details',
  //   hasChildren: false,
  //   children: null,
  //   hasIcon: false,
  //   icon: undefined,
  //   route: '/workspace/settings/details',
  //   parentName: null,
  // },
  // {
  //   id: '02-vendor-contacts',
  //   name: 'vendor-contacts',
  //   heading: 'Vendor Contacts',
  //   hasChildren: false,
  //   children: null,
  //   hasIcon: false,
  //   icon: undefined,
  //   route: '/workspace/settings/vendor-contacts',
  //   parentName: null,
  // },
  {
    id: '11-vendors',
    name: 'vendors',
    heading: 'Vendors',
    hasChildren: false,
    children: null,
    hasIcon: false,
    icon: undefined,
    route: '/workspace/settings/vendors',
    parentName: null,
  },
  {
    id: '13-members',
    name: 'members',
    heading: 'Members',
    hasChildren: false,
    children: null,
    hasIcon: false,
    icon: undefined,
    route: '/workspace/settings/members',
    parentName: null,
  },
  {
    id: '04-templates',
    name: 'templates',
    heading: 'Templates',
    hasChildren: true,
    // route: '/workspace/settings/templates',
    // route: '/workspace/settings/details',
    route: `${window.location.pathname}`,
    parentName: null,
    children: [
      {
        id: '10-checklist-template',
        name: 'checklist-templates',
        heading: 'Checklists',
        hasChildren: false,
        children: null,
        hasIcon: false,
        icon: undefined,
        route: '/workspace/settings/checklist-templates',
        parentName: 'templates',
      }, {
        id: '10-unpaid-charges-email',
        name: 'unpaid-charges-email',
        heading: 'Unpaid Charges Email',
        hasChildren: false,
        children: null,
        hasIcon: false,
        icon: undefined,
        route: '/workspace/settings/unpaid-charges-email',
        parentName: 'templates',
      },
      {
        id: '10-invoicing-document',
        name: 'invoicing-document',
        heading: 'Invoicing Document',
        hasChildren: false,
        children: null,
        hasIcon: false,
        icon: undefined,
        route: '/workspace/settings/invoicing-document',
        parentName: 'templates',
      },
      {
        id: '10-coi-deficient-notifications',
        name: 'coi-deficient-notifications',
        heading: 'COI Deficient Notification',
        hasChildren: false,
        children: null,
        hasIcon: false,
        icon: undefined,
        route: '/workspace/settings/coi-deficient-notifications',
        parentName: 'templates',
      },
      {
        id: '10-tasks-template',
        name: 'tasks-templates',
        heading: 'Tasks',
        hasChildren: false,
        children: null,
        hasIcon: false,
        icon: undefined,
        route: '/workspace/settings/tasks-templates',
        parentName: 'templates',
      },
      {
        id: '10-rfp-template',
        name: 'rfp-templates',
        heading: 'RFP Form',
        hasChildren: false,
        children: null,
        hasIcon: false,
        icon: undefined,
        route: '/workspace/settings/rfp-template',
        parentName: 'templates',
      },
    ],
    hasIcon: false,
    icon: undefined,
  },
  {
    id: '05-vendor-coi',
    name: 'vendor-coi',
    heading: 'Vendor COI',
    hasChildren: false,
    children: null,
    hasIcon: false,
    icon: undefined,
    route: '/workspace/settings/vendorCOI',
    parentName: null,
  },
  {
    id: '06-chart-of-accounts',
    name: 'chart-of-accounts',
    heading: 'Chart of Accounts',
    hasChildren: false,
    children: null,
    hasIcon: false,
    icon: undefined,
    route: '/workspace/settings/charts-of-accounts',
    parentName: null,
  },
  {
    id: '07-invoice-approval',
    name: 'invoice-approval',
    heading: 'Invoice Approval',
    hasChildren: false,
    children: null,
    hasIcon: false,
    icon: undefined,
    route: '/workspace/settings/invoice-approval',
    parentName: null,
  },
  {
    id: '08-po-approval',
    name: 'po-approval',
    heading: 'PO Approval',
    hasChildren: false,
    children: null,
    hasIcon: false,
    icon: undefined,
    route: '/workspace/settings/po-approval',
    parentName: null,
  },
  // {
  //   id: '09-annual-budgets',
  //   name: 'annual-budgets',
  //   heading: 'Annual Budgets',
  //   hasChildren: false,
  //   children: null,
  //   hasIcon: false,
  //   icon: undefined,
  //   parentName: null,
  //   route: '/workspace/settings/annual-budgets',
  // },
  // {
  //   id: '10-integration',
  //   name: 'integrations',
  //   heading: 'Integrations',
  //   hasChildren: false,
  //   children: null,
  //   hasIcon: false,
  //   icon: undefined,
  //   parentName: null,
  //   route: '/workspace/settings/integrations',
  // },

];
