/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useState, useEffect } from 'react';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Avatar from '@mui/material/Avatar';
import moment from 'moment';
import _ from 'lodash';
import Popover from '@mui/material/Popover';
import CancelIcon from '@mui/icons-material/Cancel';
import type { RootState } from 'mainStore';
import CustomCheckbox from 'shared-components/inputs/CustomCheckbox';
import type { RecentActivity, RecentActivityResponse } from 'admin/buildingSection/building-dashboard/types';
import type { Ibuilding } from 'types';

export default function RecentActivities(): JSX.Element {
  const userId = useSelector((state: RootState) => state.auth.user.id);
  const buildingList: Ibuilding[] = useSelector((state: RootState) => state.workspaces.buildingsData.data);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [filteredActivities, setFilteredActivities] = useState<RecentActivity[]>([]);
  const [selectedBuilding, setSelectedBuilding] = useState<Ibuilding[]>([]);
  const [yesterdayFlag, setYesterdayFlag] = useState(true);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopverClose = (): void => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'simple-popover' : undefined;

  const { data: recentActivities = [] } = useQuery(
    'get/notification',
    async () => axios({
      url: '/api/notifications/',
      method: 'GET',
      data: {
        user_id: userId,
      },
    }),
    {
      select: (res: AxiosResponse<RecentActivityResponse>) => res.data.detail,
    },
  );

  function NotificationRenderer(): JSX.Element {
    let currentBuilding = '';
    let currentDate = new Date();
    let isFirst = true;
    const renderDate = (date: string): string => {
      if (moment(date).format('DD-MM-YYYY') === moment(currentDate).format('DD-MM-YYYY')) {
        return 'Today';
      } if (moment(date).format('DD-MM-YYYY') === moment(new Date()).subtract(1, 'd').format('DD-MM-YYYY')) {
        return 'Yesterday';
      }
      return moment(date).format('MMMM DD, YYYY');
    };

    const allNotification: JSX.Element[] = [];

    filteredActivities.map((notification, index): JSX.Element => {
      const notificationJSX = (
        <div key={`${notification.description}-${index}`} className='notification-main-wapper'>
          {(moment(notification.time).format('DD-MM-YYYY') !== moment(currentDate).format('DD-MM-YYYY') || isFirst) && (
            <div className='date-tag'>
              {renderDate(notification.time)}
            </div>
          )}
          {renderDate(notification.time) === 'Yesterday' && yesterdayFlag ? (
            <div className='building-tag'>
              {notification.property_info }
            </div>
          ) : renderDate(notification.time) !== 'Yesterday' && renderDate(notification.time) !== 'Today'
            ? (
              <div className='building-tag'>
                {notification.property_info }
              </div>
            ) : (notification.property_info !== currentBuilding) && (
              <div className='building-tag'>
                {notification.property_info}
              </div>
            )}

          <div className='single-activity-wrapper'>
            <h5>
              {' '}
              {notification.description.substring(
                notification.description.indexOf(':') + 1,
                notification.description.indexOf(';'),
              )}
              {' '}
            </h5>
            <div className='card-box'>
              <Avatar className='activity-avatar' src={`${process.env.REACT_APP_IMAGE_URL}${notification.user_info.avatar}`} />
              <div className='right-side'>
                <h6>
                  {' '}
                  {notification.user_info.name}
                  <span>
                    {notification.description.substring(
                      0,
                      notification.description.indexOf(':'),
                    )}
                    {notification.description.substring(
                      notification.description.indexOf(';') + 1,
                      notification.description.length,
                    )}
                  </span>
                </h6>
                <p>
                  {' '}
                  {moment(notification.time).format('hh:mm A')}
                  {' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      );

      allNotification.push(notificationJSX);
      if (notification.property_info !== currentBuilding) {
        currentBuilding = notification.property_info;
      }
      if (moment(notification.time).format('DD-MM-YYYY') !== moment(currentDate).format('DD-MM-YYYY')) {
        currentDate = moment(notification.time).toDate();
      }
      isFirst = false;

      return <div />;
    });

    return (
      <div>
        {allNotification}
      </div>
    );
  }

  const handleCheckboxChange = (building: Ibuilding): void => {
    setYesterdayFlag(true);
    const isBuildingInclude = selectedBuilding
      .map((b) => b.id)
      .includes(building.id);
    if (isBuildingInclude) {
      const newBuilding = _.reject(
        selectedBuilding,
        (singleBuilding) => singleBuilding.id === building.id,
      );
      setSelectedBuilding(newBuilding);
    } else {
      setSelectedBuilding([...selectedBuilding, building]);
    }
  };

  useEffect(() => {
    if (selectedBuilding.length > 0) {
      const filterdList = recentActivities.filter((rc) => _.includes(selectedBuilding.map((b) => b.id), rc.property));
      setFilteredActivities(filterdList);
    } else {
      setFilteredActivities(recentActivities);
    }
  }, [buildingList, selectedBuilding, recentActivities]);

  return (
    <div className='recent-activity-wrapper'>
      <div className='ra-building-filter header'>
        <div
          className={
            selectedBuilding.length === 0
              ? 'building-pop-button'
              : 'building-pop-button active-building-btn'
          }
          onClick={handleClick}
          aria-hidden='true'
        >
          <p>
            Building:
            {'  '}
            {selectedBuilding.map((b) => b.address)}
            {'  '}
            {selectedBuilding.length !== 0 && (
              <span
                onClick={(e): void => {
                  setSelectedBuilding([]);
                  e.stopPropagation();
                }}
                aria-hidden='true'
              >
                {'  '}
                <CancelIcon
                  htmlColor='rgba(0, 0, 0, 0.26)'
                  fontSize='small'
                />
                {'  '}
              </span>
            )}
          </p>
          {isOpen ? <ArrowDropUpIcon htmlColor='rgba(33, 33, 33, 0.6)' /> : <ArrowDropDownIcon htmlColor='rgba(33, 33, 33, 0.6)' />}

        </div>
        <Popover
          id={id}
          open={isOpen}
          anchorEl={anchorEl}
          onClose={handlePopverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className='event-building-list'>
            {buildingList.map((b) => (
              <div className='item' key={`${b.address}-${Math.random()}`}>
                <CustomCheckbox
                  label=''
                  checked={selectedBuilding
                    .map((blding) => blding.id)
                    .includes(b.id)}
                  name='first_name'
                  onChange={(): void => {
                    handleCheckboxChange(b);
                  }}
                />
                <p>
                  {' '}
                  {b.address}
                  {' '}
                </p>
              </div>
            ))}
          </div>
        </Popover>
      </div>
      <NotificationRenderer />
    </div>
  );
}
