/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Grid, Typography, Divider,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import './_emailTemplate.scss';
import React, { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import DeleteIcon from '@mui/icons-material/Delete';
import PrimayButton from 'shared-components/components/PrimayButton';
import HookTextField from 'shared-components/hooks/HookTextField';
import type { IFormValues } from 'formsTypes';
import StradaLoader from 'shared-components/components/StradaLoader';
import type { Iinput } from './types';
import type { Ierror } from '../types';

interface Iprops {
  emailData: Ierror;
  setEmailData: () => void;
}
export default function AddTemplate(props: Iprops): JSX.Element {
  const { emailData, setEmailData } = props;
  const [files, setFiles] = useState<File[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const defaultInput: Iinput = {
    email: '',
    subject: '',
  };
  const schema = yup.object().shape({
    email: yup.string().trim().required('Email is required').email('Enter valid email'),
    subject: yup.string().trim().required('Field is required'),

  });
  const {
    control, formState, handleSubmit, setValue, watch,
  } = useForm<IFormValues>({
    mode: 'onChange',
    defaultValues: defaultInput,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue('context', emailData.insured_email, { shouldDirty: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailData]);
  const { mutate: sendEmail, isLoading } = useMutation(async (payload: FormData) => axios({
    url: '/api/send-cois-email/',
    method: 'post',
    data: payload,
  }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('get/cois-error').catch()
        .then();
      enqueueSnackbar('Email Sent');
      setEmailData();
    },
  });

  const { errors } = formState;

  const onSubmit: SubmitHandler<IFormValues> = (paylaodData: IFormValues) => {
    const data = new FormData();
    data.append('email', paylaodData.email);
    data.append('context', paylaodData.context);
    data.append('subject', paylaodData.subject);
    data.append('cois_id', String(emailData.cois_id));

    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      data.append('files', file);
    }
    sendEmail(data);
  };

  const fileHandler: (e: React.ChangeEvent<HTMLInputElement>) => void = (e) => {
    if (e.target.files?.length !== undefined) {
      setFiles([...files, e.target.files[0]]);
    }
  };
  const handleChangeTextarea: (event: React.ChangeEvent<HTMLTextAreaElement>) => void = (event) => {
    setValue('context', event.target.value, { shouldDirty: true });
  };

  const deleteFileHandler: (index: number) => void = (index) => {
    const filteredFiles = files.filter((item, i) => i !== index);
    setFiles(filteredFiles);
  };
  /* eslint-disable @typescript-eslint/no-misused-promises */
  return (
    <div className='cois-email-template'>
      <StradaLoader open={isLoading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columnSpacing={3}>

          <Grid item sm={12} md={12}>
            <div className='header'>
              <i className='fa-solid fa-chevron-left icon' onClick={setEmailData} aria-hidden />
              <p>Deficient certifications</p>
            </div>
            <Typography className='sub-header'>
              {emailData.insured}
            </Typography>

          </Grid>
          <Grid item sm={12} md={12}>
            <HookTextField
              name='email'
              label='To*'
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item sm={12} md={12}>
            <HookTextField
              name='subject'
              label='Subject*'
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item sm={12} md={12}>
            <TextareaAutosize
              className='text-area'
              minRows={18}
              aria-label='maximum height'
              value={watch('context')}
              onChange={handleChangeTextarea}
            />
          </Grid>

          <Grid item sm={12} md={12}>
            <div className='attachment-container'>
              <label htmlFor='attachment-button' className='d-flex'>
                <i className='fa-solid fa-paperclip' />
                <p>Attach Document</p>
              </label>

            </div>
            <input type='file' id='attachment-button' style={{ display: 'none' }} onChange={fileHandler} />
            <Divider light />
            <div>
              <div className='file-listing'>
                <p>{emailData.coi_file_name}</p>
              </div>
              <Divider light />
              {files.length > 0
            && files.map((file, index) => (
              <div>
                <div className='file-listing '>
                  <p>{file.name}</p>
                  <DeleteIcon className='icon' onClick={(): void => { deleteFileHandler(index); }} />
                </div>
                <Divider light />
              </div>
            ))}
            </div>

          </Grid>

        </Grid>
        <Grid className='d-flex justify-content-end' my={3}>
          <div>
            <PrimayButton type='submit'>
              Send Notice
            </PrimayButton>
          </div>
        </Grid>
      </form>

    </div>

  );
}
