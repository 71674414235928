/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react';
import {
  IconButton, Typography, Grid, InputBase,
} from '@mui/material';
import type { KeyboardEvent } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import type { Cell } from 'react-table';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import StradaLoader from 'shared-components/components/StradaLoader';
import CustomTable from 'shared-components/tables/CustomTable';
import PrimayButton from 'shared-components/components/PrimayButton';
import type { RootState } from 'mainStore';
import type { IDataObject } from 'formsTypes';
import { encrypt } from 'shared-components/hooks/useEncryption';
import type {
  Iresponse, IvendorResponse, Idata,
} from './types';
import EditCOI from './EditCOIs/EditContent';
import type { IeditCOIs } from '../AdminFormTypes';
import DetailSideSheet from './DetailSideSheet';
import COIsErrorBar from './COIsErrorTemplate/COIsErrorBar';
import VendorSelect from './VendorSelect';

export default function UnpaidChagresEmailContent(): JSX.Element {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [openEditModal, setopenEditModal] = React.useState(false);
  const [editData, setEditData] = React.useState<IeditCOIs | undefined>();
  const [viewProperties, setviewProperties] = React.useState<IeditCOIs | undefined>();
  const [showSidesheet, setshowSidesheet] = React.useState(false);
  const [inputText, setInputText] = React.useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = React.useState('');
  const [filteredData, setFilteredData] = useState<Idata[]>();

  const currentBuilding = useSelector(
    (state: RootState) => state.workspaces.currentBuilding,
  );

  const { data: getVendorsList } = useQuery(
    'get/vendor',
    async () => axios({
      url: `api/vendor-category/?property_id=${currentBuilding.id}`,
      method: 'get',
    }),
    {
      enabled: currentBuilding.id !== 0,
      select: (res: AxiosResponse<IvendorResponse>) => {
        const list = [{ label: 'Vendor category', value: -1 }];
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (res !== undefined) {
          res.data.result.map((vendor) => {
            const obj = {
              label: `${vendor.name ? vendor.name : ''}`,
              value: vendor.id,
            };
            list.push(obj);
            return obj;
          });
        }
        return list;
      },
    },
  );

  const { mutate: deleteCOI } = useMutation(async (id: number | string) => axios({
    url: `/api/coi/${id}/`,
    method: 'delete',
  }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('get/cois').catch()
        .then();
      enqueueSnackbar('Deleted Successfully!');
    },
    onError: () => {
      enqueueSnackbar('Failed!', { variant: 'error' });
    },
  });

  const colors = [
    {
      status: 'No Data',
      color: '#C62828',
    },
    {
      status: 'Success',
      color: '#4CAF50',
    },
    {
      status: 'uncategorized',
      color: '#2196f3',
    },
    {
      status: 'none',
      color: '#F9A825',
    },
  ];

  const handleEdit: () => void = () => {
    setopenEditModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Vendor',
        accessor: 'insured',
        width: '20%',
      },
      {
        Header: 'Category',
        accessor: 'vendor_category',
        width: '20%',

        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { row } = cell;
          const { original } = row;
          return (
            <div className='cois-table-dropdown'>
              <VendorSelect id={original.id} vendorList={getVendorsList === undefined ? [] : getVendorsList} defaultValue={original.vendor_category} />
            </div>
          );
        },

      },
      {
        Header: 'G/L Account',
        accessor: 'gl_accounts',
        width: '20%',
      },
      {
        Header: 'Expires',
        accessor: 'general_liability_exp_date',

        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { value } = cell;
          const newVal: string | null = value;
          return (
            <div>
              {newVal !== null ? moment(newVal).format('MM/DD/YYYY') : ''}
            </div>
          );
        },
      },
      {
        Header: 'Data Added',
        accessor: 'created_at',
        width: '12%',

        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { value } = cell;
          const newVal: string | null = value;
          return (
            <div>
              {newVal !== null ? moment(newVal).format('MM/DD/YYYY') : ''}
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '10%',
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { value } = cell;
          const statusColor = colors.filter((item) => item.status === value);
          return (
            <div
              className='status-values-cois'
              style={{
                color: statusColor.length > 0 ? statusColor[0].color : '#F9A825',
              }}
            >
              {value}
            </div>

          );
        },
      },
      {
        Header: '',
        accessor: 'popper',
        width: '6%',
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { row } = cell;
          const { original } = row;
          return (
            <div
              style={{
                textAlign: 'right',
                marginRight: '1rem',
              }}
            >
              <PopupState variant='popper' popupId='demo-popup-popper'>
                {(popupState): JSX.Element => (
                  <div>
                    <IconButton {...bindToggle(popupState)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Popper {...bindPopper(popupState)} transition>
                      {({ TransitionProps }): JSX.Element => (
                        <ClickAwayListener
                          onClickAway={(): void => {
                            popupState.close();
                          }}
                        >
                          <Fade {...TransitionProps} timeout={350}>
                            <Paper className='cois-popover'>
                              <div
                                className='chart-btn'
                                onClick={(): void => {
                                  handleEdit();
                                  setEditData(original);
                                  popupState.close();
                                }}
                                aria-hidden='true'
                              >
                                <i className='fas fa-pen edit-icon fa-lg edit-icons' />
                                <span className='edit-text'> Edit</span>
                              </div>
                              <div
                                className='chart-btn'
                                onClick={(): void => {
                                  setviewProperties(original);
                                  setEditData(original);
                                  setshowSidesheet(true);
                                  popupState.close();
                                }}
                                aria-hidden='true'
                              >
                                <VisibilityIcon className='edit-icons' />
                                <span className='view-properties'>
                                  View Properties
                                </span>
                              </div>
                              <div
                                className='chart-btn'
                                onClick={(): void => {
                                  deleteCOI(original.id);
                                  popupState.close();
                                }}
                                aria-hidden='true'
                              >
                                <DeleteIcon className='edit-icons' />
                                <span className='delete-text'>
                                  Delete
                                </span>
                              </div>
                            </Paper>
                          </Fade>
                        </ClickAwayListener>
                      )}
                    </Popper>
                  </div>
                )}
              </PopupState>
            </div>
          );
        },
      },
    ],
    [currentBuilding, getVendorsList],
  );

  const { data, isLoading } = useQuery(
    'get/cois',
    async () => axios({
      url: `api/coi/?property_id=${currentBuilding.id}`,
      method: 'get',
    }),
    {
      enabled: currentBuilding.id !== 0,
      select: (res: AxiosResponse<Iresponse>) => res.data.result,
    },
  );

  const handleSearch: (e: KeyboardEvent<HTMLImageElement>) => void = (e: KeyboardEvent<HTMLImageElement>) => {
    if (e.key === 'Enter') {
      setSearch(inputText);
    }
  };

  useEffect(() => {
    if (data !== undefined && data.length > 0) {
      const filtered = data.filter((element) => element.insured.toLowerCase().trim().includes(inputText.toLowerCase().trim()));
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [inputText, data]);

  const closeEditModal: () => void = () => {
    setopenEditModal(false);
  };

  return (
    <>

      <div className='cois-wrapper' style={{ marginRight: showSidesheet ? '323px' : '39px' }}>
        <StradaLoader open={isLoading} />
        <div className='header'>
          <Typography className='header-text'>COIs</Typography>
          <div className='d-flex'>
            <Grid item sm={6} className='search-field'>
              <Paper elevation={0} className='search-paper'>
                <InputBase
                  value={inputText}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => { setInputText(e.target.value); }}
                  onBlur={(): void => { setSearch(inputText); }}
                  onKeyPress={handleSearch}
                  placeholder='Search'
                  endAdornment={(
                    <IconButton style={{ padding: 0 }} onClick={(): void => { setSearch(inputText); }}>
                      <SearchIcon className='search-icon' />
                      {' '}
                    </IconButton>
                  )}
                />

                {' '}
              </Paper>
            </Grid>
            <div className='button-wrapper '>
              <PrimayButton
                onClick={(): void => {
                  navigate(`/building/cois/${encrypt(currentBuilding.id)}/new`);
                }}
              >
                Add New COI
              </PrimayButton>
            </div>
          </div>
        </div>

        <div>
          <COIsErrorBar />
          <CustomTable
            {...{
              columns,
              data: filteredData !== undefined ? filteredData : [],
            }}
          />
        </div>
      </div>
      {showSidesheet && (
        <div className='coi-details-sidesheet'>
          <DetailSideSheet
            openEditModal={(): void => {
              setopenEditModal(true);
              setshowSidesheet(false);
            }}
            viewProperties={viewProperties}
            showSidesheet={(): void => { setshowSidesheet(false); }}
          />
        </div>
      )}
      <EditCOI handleClose={closeEditModal} open={openEditModal} editData={editData} />

    </>
  );
}
