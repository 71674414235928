/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import PrimayButton from 'shared-components/components/PrimayButton';
import StradaLoader from 'shared-components/components/StradaLoader';
import type { RootState } from 'mainStore';

import Filters from 'admin/buildingSection/budget-calendar/components/Filters';
import type { IEvent, IEventResponse, IAllEventResponse } from 'admin/buildingSection/budget-calendar/types';
import 'admin/buildingSection/budget-calendar/_budget-calendar.scss';
import { useParams } from 'react-router-dom';
import { decrypt } from 'shared-components/hooks/useEncryption';
import TableSection from './TableSection';

export default function AllEvents(): JSX.Element {
  const queryClient = useQueryClient();
  const currentWorkspace = useSelector((state: RootState) => state.workspaces.currentWorkspace.currentWorkspace);
  const { enqueueSnackbar } = useSnackbar();
  const { buildingId } = useParams();
  const [date, setDate] = useState<Date>(new Date());
  const [search, setSearch] = useState< string>('');
  const [assignees, setAssignees] = useState<number[]>([]);
  const [occurrences, setOccurrences] = useState<number[]>([]);
  const [moves, setMoves] = useState<number[]>([]);
  const [createdFilters, setCreatedFilters] = useState<number[]>([]);
  const [projectFiltrs, setProjectFilters] = useState<number[]>([]);
  const [statusFilter, setStatusFilter] = useState<number[]>([]);
  const [newEventOpen, setNewEventOpen] = useState<boolean>(false);
  // const [nextDataUrl, setNextDataUrl] = useState<string | undefined>();
  const [getNextData, setgetNextData] = useState<boolean>(false);

  let nextDataUrl: string | undefined = '';
  const [allEvents, setAllEvents] = useState<IEvent[]>([]);

  // functions
  const handleResetFilters = (): void => {
    setDate(new Date());
    setSearch('');
    setAssignees([]);
    setOccurrences([]);
    setMoves([]);
    setCreatedFilters([]);
    setProjectFilters([]);
    setStatusFilter([]);
  };

  const { isLoading: loadingEvents } = useQuery(['allevents/get-events', search, statusFilter, assignees, occurrences, createdFilters, projectFiltrs], async () => axios({
    url: '/api/dashboard/all-events/',
    method: 'GET',
    params: {
      search,
      status: statusFilter,
      assignee: assignees,
      occurrence: occurrences,
      is_annual_budget_event: createdFilters,
      tag: projectFiltrs,
    },
  }), {
    select: (res: AxiosResponse<IAllEventResponse>) => {
      if (res.data.next !== undefined) {
        if (res.data.next !== null) {
          nextDataUrl = res.data.next.split('/api')[1] ? res.data.next.split('/api')[1] : '';
        } else {
          nextDataUrl = '';
        }
      }
      return res.data.detail;
    },
    onSuccess: (res) => { setAllEvents([...res]); },
  });

  // const { isLoading: getAllEvents } =
  useQuery('allevents', async () => axios({
    url: `/api${nextDataUrl}`,
    method: 'GET',
    params: {
      search,
      status: statusFilter,
      assignee: assignees,
      occurrence: occurrences,
      is_annual_budget_event: createdFilters,
      tag: projectFiltrs,
    },
  }), {
    select: (res: AxiosResponse<IEventResponse>) => {
      if (res.data.next !== undefined) {
        if (res.data.next !== null) {
          nextDataUrl = res.data.next.split('/api')[1] ? res.data.next.split('/api')[1] : '';
        } else {
          nextDataUrl = '';
        }
      }
      return res.data.results;
    },
    onSuccess: (res) => { setAllEvents([...allEvents, ...res]); },
    enabled: getNextData && Boolean(nextDataUrl),
  });

  const { mutate: createEvent, isLoading: creatingEvent } = useMutation(async (title: string) => axios({
    url: '/api/budget-calendar/event/',
    method: 'POST',
    data: {
      date: moment(date).format('YYYY-MM-DD'),
      month: date.getMonth() + 1,
      property: decrypt(buildingId),
      title,
      workspace: currentWorkspace.id,
    },
  }), {
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries('allevents/get-events')
        .then();

      enqueueSnackbar('Event created successfully');
    },
  });

  return (
    <div className='bc-container' style={{ marginTop: '0px' }}>
      <StradaLoader open={loadingEvents || creatingEvent} message={creatingEvent ? 'Creating Event' : 'Loading Events'} />
      <div className='all-events-filter-wrapper'>
        <div className='left-side'>
          <Filters
            search={search}
            setSearch={setSearch}
            assignees={assignees}
            setAssignees={setAssignees}
            occurrences={occurrences}
            setOccurrences={setOccurrences}
            moves={moves}
            setMoves={setMoves}
            createdFilters={createdFilters}
            setCreatedFilters={setCreatedFilters}
            projectFiltrs={projectFiltrs}
            setProjectFilters={setProjectFilters}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            handleResetFilters={handleResetFilters}
          />
        </div>
        <div className='btn-wrapper add-event-btn'>
          <PrimayButton onClick={(): void => { setNewEventOpen(true); }}> Add Event </PrimayButton>
        </div>
      </div>

      <TableSection events={allEvents} newEventOpen={newEventOpen} setNewEventOpen={setNewEventOpen} createEvent={createEvent} setgetNextData={setgetNextData} />

    </div>

  );
}
