import React from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export function SideBarHeaderIcon(): JSX.Element {
  return (
    <svg width='30' height='28' viewBox='0 0 30 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M24 18.5V21.5234H20.9766V18.5H24ZM24 12.5234V15.4766H20.9766V12.5234H24ZM27.0234 24.4766V9.5H15V12.5234H18.0234V15.4766H15V18.5H18.0234V21.5234H15V24.4766H27.0234ZM11.9766 6.47656V3.52344H9.02344V6.47656H11.9766ZM11.9766 12.5234V9.5H9.02344V12.5234H11.9766ZM11.9766 18.5V15.4766H9.02344V18.5H11.9766ZM11.9766 24.4766V21.5234H9.02344V24.4766H11.9766ZM6 6.47656V3.52344H2.97656V6.47656H6ZM6 12.5234V9.5H2.97656V12.5234H6ZM6 18.5V15.4766H2.97656V18.5H6ZM6 24.4766V21.5234H2.97656V24.4766H6ZM15 6.47656H29.9766V27.5H0.0234375V0.5H15V6.47656Z' fill='#00CFA1' />
    </svg>
  );
}

export function BudgetCalendarIcon(): JSX.Element {
  return (
    <DateRangeIcon />
  );
}

export function ChecklistIcon(): JSX.Element {
  return <FormatListBulletedIcon />;
}

export function CIOIcon(): JSX.Element {
  return (
    <svg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M9.98438 7H15.5156L9.98438 1.51562V7ZM12.9844 12.0156V10H5.01562V12.0156H12.9844ZM12.9844 16V13.9844H5.01562V16H12.9844ZM11.0156 0.015625L17.0156 6.01562V18.0156C17.0156 18.5469 16.8125 19.0156 16.4062 19.4219C16 19.7969 15.5312 19.9844 15 19.9844H3C2.46875 19.9844 2 19.7969 1.59375 19.4219C1.1875 19.0156 0.984375 18.5469 0.984375 18.0156L1.03125 1.98438C1.03125 1.45313 1.21875 1 1.59375 0.625C2 0.21875 2.46875 0.015625 3 0.015625H11.0156Z' fill='#212121' fillOpacity='0.6' />
    </svg>
  );
}

export function UnpaidChargesIcon(): JSX.Element {
  return (
    <svg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0 19.9844V0.015625L1.5 1.51562L3 0.015625L4.5 1.51562L6 0.015625L7.5 1.51562L9 0.015625L10.5 1.51562L12 0.015625L13.5 1.51562L15 0.015625L16.5 1.51562L18 0.015625V19.9844L16.5 18.4844L15 19.9844L13.5 18.4844L12 19.9844L10.5 18.4844L9 19.9844L7.5 18.4844L6 19.9844L4.5 18.4844L3 19.9844L1.5 18.4844L0 19.9844ZM15 7V4.98438H3V7H15ZM15 10.9844V9.01562H3V10.9844H15ZM15 15.0156V13H3V15.0156H15Z' fill='#212121' fillOpacity='0.6' />
    </svg>
  );
}

export function InvoicingIcon(): JSX.Element {
  return (
    <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M11.9062 10.0781C12.2188 10.3594 12.5781 10.5 12.9844 10.5C13.3906 10.5 13.7344 10.3594 14.0156 10.0781C14.3281 9.79688 14.4844 9.4375 14.4844 9C14.4844 8.5625 14.3281 8.20312 14.0156 7.92188C13.7344 7.64062 13.3906 7.5 12.9844 7.5C12.5781 7.5 12.2188 7.64062 11.9062 7.92188C11.625 8.20312 11.4844 8.5625 11.4844 9C11.4844 9.4375 11.625 9.79688 11.9062 10.0781ZM9 12.9844V5.01562H18.9844V12.9844H9ZM18 15V15.9844C18 16.5156 17.7969 16.9844 17.3906 17.3906C16.9844 17.7969 16.5156 18 15.9844 18H2.01562C1.45312 18 0.96875 17.8125 0.5625 17.4375C0.1875 17.0312 0 16.5469 0 15.9844V2.01562C0 1.45312 0.1875 0.984375 0.5625 0.609375C0.96875 0.203125 1.45312 0 2.01562 0H15.9844C16.5156 0 16.9844 0.203125 17.3906 0.609375C17.7969 1.01563 18 1.48438 18 2.01562V3H9C8.4375 3 7.95312 3.20312 7.54688 3.60938C7.17188 3.98438 6.98438 4.45312 6.98438 5.01562V12.9844C6.98438 13.5469 7.17188 14.0312 7.54688 14.4375C7.95312 14.8125 8.4375 15 9 15H18Z' fill='#212121' fillOpacity='0.6' />
    </svg>
  );
}

export function PurhcaseOrderIcon(): JSX.Element {
  return (
    <svg width='24' height='19' viewBox='0 0 24 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M10.5938 14.4062C11 14.8125 11.4688 15.0156 12 15.0156C12.5312 15.0156 13 14.8125 13.4062 14.4062C13.8125 14 14.0156 13.5312 14.0156 13C14.0156 12.4688 13.8125 12 13.4062 11.5938C13 11.1875 12.5312 10.9844 12 10.9844C11.4688 10.9844 11 11.1875 10.5938 11.5938C10.1875 12 9.98438 12.4688 9.98438 13C9.98438 13.5312 10.1875 14 10.5938 14.4062ZM9 7H15L12 2.59375L9 7ZM17.2031 7H21.9844C22.2656 7 22.5 7.09375 22.6875 7.28125C22.9062 7.46875 23.0156 7.70312 23.0156 7.98438C22.7031 9.23438 22.2344 11 21.6094 13.2812C20.9844 15.5312 20.5938 16.9531 20.4375 17.5469C20.1562 18.5156 19.5156 19 18.5156 19H5.48438C4.48438 19 3.84375 18.5156 3.5625 17.5469L1.03125 8.26562C1 8.20312 0.984375 8.10938 0.984375 7.98438C0.984375 7.70312 1.07812 7.46875 1.26562 7.28125C1.48438 7.09375 1.73438 7 2.01562 7H6.79688L11.1562 0.4375C11.3438 0.15625 11.625 0.015625 12 0.015625C12.4062 0.015625 12.6875 0.15625 12.8438 0.4375L17.2031 7Z' fill='#212121' fillOpacity='0.6' />
    </svg>
  );
}

export function BuildingSettings(): JSX.Element {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.51562 12.4844C8.20312 13.1719 9.03125 13.5156 10 13.5156C10.9688 13.5156 11.7969 13.1719 12.4844 12.4844C13.1719 11.7969 13.5156 10.9688 13.5156 10C13.5156 9.03125 13.1719 8.20312 12.4844 7.51562C11.7969 6.82812 10.9688 6.48438 10 6.48438C9.03125 6.48438 8.20312 6.82812 7.51562 7.51562C6.82812 8.20312 6.48438 9.03125 6.48438 10C6.48438 10.9688 6.82812 11.7969 7.51562 12.4844ZM17.4531 10.9844L19.5625 12.625C19.7812 12.7812 19.8125 13 19.6562 13.2812L17.6406 16.75C17.5156 16.9688 17.3125 17.0312 17.0312 16.9375L14.5469 15.9531C13.8906 16.4219 13.3281 16.75 12.8594 16.9375L12.4844 19.5625C12.4219 19.8438 12.2656 19.9844 12.0156 19.9844H7.98438C7.73438 19.9844 7.57812 19.8438 7.51562 19.5625L7.14062 16.9375C6.54688 16.6875 5.98438 16.3594 5.45312 15.9531L2.96875 16.9375C2.6875 17.0312 2.48438 16.9688 2.35938 16.75L0.34375 13.2812C0.1875 13 0.21875 12.7812 0.4375 12.625L2.54688 10.9844C2.51562 10.7656 2.5 10.4375 2.5 10C2.5 9.5625 2.51562 9.23438 2.54688 9.01562L0.4375 7.375C0.21875 7.21875 0.1875 7 0.34375 6.71875L2.35938 3.25C2.48438 3.03125 2.6875 2.96875 2.96875 3.0625L5.45312 4.04688C6.10938 3.57812 6.67188 3.25 7.14062 3.0625L7.51562 0.4375C7.57812 0.15625 7.73438 0.015625 7.98438 0.015625H12.0156C12.2656 0.015625 12.4219 0.15625 12.4844 0.4375L12.8594 3.0625C13.4531 3.3125 14.0156 3.64062 14.5469 4.04688L17.0312 3.0625C17.3125 2.96875 17.5156 3.03125 17.6406 3.25L19.6562 6.71875C19.8125 7 19.7812 7.21875 19.5625 7.375L17.4531 9.01562C17.4844 9.23438 17.5 9.5625 17.5 10C17.5 10.4375 17.4844 10.7656 17.4531 10.9844Z' fill='#212121' fillOpacity='0.6' />
    </svg>
  );
}
