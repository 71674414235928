/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Popover } from '@mui/material';
import React from 'react';
import { useQuery, useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import type { Ibuilding } from 'types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { encrypt } from 'shared-components/hooks/useEncryption';

interface INotiProps {
  open: boolean;
  handleClose: () => void;
  anchorEl: HTMLElement | null;
  id: string | undefined;
}

interface IUserInfo {
  avatar: string;
  first_name: string;
  last_name: string;
}

interface INotification {
  description: string;
  event: number;
  property: number;
  property_info: string;
  time: string;
  type: string;
  user_info: IUserInfo;
}

export function NotificationPopover(props: INotiProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  let date = '';
  let property = '';
  const {
    open, handleClose, anchorEl, id,
  } = props;

  const { mutate: GetNotifications, data: notiData } = useMutation(async (data: number[]) => axios({
    url: 'api/notifications/',
    method: 'GET',
    data: { id: data },
  }), {
    onError: () => {
      enqueueSnackbar('Couldnot refresh data', { variant: 'error' });
    },
  });

  useQuery(
    'get/buildings',
    async () => axios({
      url: 'api/property-manager-property/',
      method: 'get',
    }),
    {
      select: (res) => res.data.result,
      onSuccess: (res) => {
        const ids: number[] = [];
        if (res.length > 0) {
          res.forEach((building: Ibuilding) => {
            ids.push(building.id);
          });
          GetNotifications(ids);
        }
      },
    },
  );

  // useEffect(() => {
  //   const ids: number[] = [];
  //   if (buildingsData.length > 0) {
  //     buildingsData.forEach((building: Ibuilding) => {
  //       ids.push(building.id);
  //     });
  //     console.log(ids);
  //     GetNotifications(ids);
  //   }
  // }, [buildingsData]);

  const handleNotificationClick = (notification: INotification): void => {
    if (notification.type !== '' && notification.type !== 'task') {
      handleClose();
      navigate(`/building/budget-calendar/${encrypt(notification.property)}?eventId=${notification.event}`);
    }
  };

  const getName = (notification: INotification): JSX.Element => {
    if (notification.user_info !== undefined) {
      if (notification.user_info.first_name || notification.user_info.last_name) {
        return (
          <div className='name text-dark'>
            {notification.user_info.first_name}
            &nbsp;
            {notification.user_info.last_name}
            &nbsp;
          </div>
        );
      }
      return <div className='name text-dark'>Default User</div>;
    }
    return <div />;
  };

  const getDescription = (notification: INotification): (JSX.Element | string)[] => {
    const splited = notification.description.split(' :');
    const data = splited.map((splits) => {
      if (splits.includes(';')) {
        const parts = splits.split(';');
        return (
          <React.Fragment key={parts[0]}>
            <span className={`noti-link ${notification.type !== '' && 'cursor-pointer'}`} aria-hidden='true' onClick={(): void => { handleNotificationClick(notification); }}>
            &nbsp;
              {parts[0]}
            </span>
            {parts[1]}
          </React.Fragment>
        );
      }
      return splits;
    });
    return data;
  };

  return (
    <Popover
      id={id}
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >

      <div className='notification-popover'>
        <div className='heading'>
          <div className='head'>Notifications</div>
          <div className='close-btn' aria-hidden='true' onClick={handleClose}>
            <i className='fas fa-times' />
          </div>
        </div>
        {notiData?.data.result !== undefined && notiData.data.result.length > 0 && notiData.data.result.map((notification: INotification) => {
          let newDate = true;
          let NewProperty = true;
          let type = 'other';
          let avatarUrl = '';
          const yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);

          if (date === moment(notification.time).format('YYYY-MM-DD')) {
            newDate = false;
          } else {
            date = moment(notification.time).format('YYYY-MM-DD');
            property = '';
            if (moment(notification.time).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')) {
              type = 'today';
            } else if (moment(notification.time).format('YYYY-MM-DD') === moment(yesterday).format('YYYY-MM-DD')) {
              type = 'yesterday';
            }
          }

          if (property === notification.property_info) {
            NewProperty = false;
          } else {
            property = notification.property_info;
          }

          if (notification.user_info !== undefined) {
            avatarUrl = `${process.env.REACT_APP_IMAGE_URL}/${notification.user_info.avatar}`;
          } else {
            avatarUrl = `${process.env.REACT_APP_IMAGE_URL}/media/avatar/defaultAvatar.png`;
          }

          return (
            <div className='notification-panel' key={notification.time}>
              {newDate && (
                <div className='date-card'>
                  {type === 'other' ? moment(notification.time).format('MMMM DD, YYYY') : type}
                </div>
              )}

              {NewProperty && <div className='property'>{notification.property_info}</div>}
              <div className='history-body'>
                <div className=' history-details'>
                  <div className='notification-logo'>
                    <Avatar src={avatarUrl} />
                  </div>
                  <div className='desc'>
                    {getName(notification)}
                    {getDescription(notification)}
                  </div>
                </div>
                <div className='history-time'>{moment(notification.time).format('hh:mm A')}</div>
              </div>
            </div>
          );
        })}
      </div>
    </Popover>
  );
}
