/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import type { AxiosResponse } from 'axios';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import type { OptionProps, StylesConfig } from 'react-select';
import Select from 'react-select';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { decrypt } from 'shared-components/hooks/useEncryption';
import StradaSpinner from 'shared-components/components/StradaSpinner';
import type { IGLResponse as IGLResponseYardi } from 'admin/buildingSection/budget-calendar/types';
import { useSelector } from 'react-redux';
import type { RootState } from 'mainStore';
import type { IGLData, IGLResponse, IStatus } from 'admin/purchaseOrder/types';

interface IGLProps {
  name: string;
  value: number;
}

const selectStyles: StylesConfig<IGLProps> = {
  control: () => ({
    border: 'none',
    width: '100%',
    // background: 'red',
    display: 'flex',
    borderRadius: '4px',
  }),
  valueContainer: () => ({
    height: '40px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  }),
  singleValue: () => ({
    color: 'rgba(33, 33, 33, 0.6)',
    fontSize: '14px',
  }),
  // input: () => ({
  //   width: '100%',
  // }),
};

export default function GLCodeBar(): JSX.Element {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { buildingId } = useParams();
  const currentWorkspace = useSelector((state: RootState) => state.workspaces.currentWorkspace.currentWorkspace);
  const [selectedCode, setSelectedCode] = React.useState<IStatus | null>(null);
  const currentBuilding = useSelector((state: RootState) => state.workspaces.currentBuilding);
  const [finalGLList, setFinalGLList] = React.useState<IStatus[]>([]);
  const sideSheetData = useSelector((state: RootState) => state.workspaces.sideSheetData.sideSheetData);

  const { data: glCodesYardi = [] } = useQuery(
    ['sidesheet/get-gl-code'],
    async () => axios({
      url: '/api/yardi/export_chart_of_accounts/',
      method: 'post',
      data: {
        id: Number(decrypt(buildingId)),
      },
    }),
    {
      enabled: currentBuilding.yardi_connected,
      select: (res: AxiosResponse<IGLResponseYardi>) => res.data.result.map((gl) => ({ name: gl.gl_account, value: gl.gl_code })),
    },
  );

  const { data: glCodesBackend = [] } = useQuery(
    'get-gl-accounts',
    async () => axios({
      url: `api/filter/gl-code/?workspace=${currentWorkspace.id}`,
      method: 'get',
    }),
    {
      enabled: !currentBuilding.yardi_connected,
      select: (res: AxiosResponse<IGLResponse>) => res.data.detail.map((gl: IGLData) => ({ name: gl.label, value: gl.id })),
    },
  );

  useEffect(() => {
    if (glCodesBackend.length > 0) {
      setFinalGLList(glCodesBackend);
    }
  }, [glCodesBackend]);

  useEffect(() => {
    if (glCodesYardi.length > 0) {
      setFinalGLList(glCodesYardi);
    }
  }, [glCodesYardi]);

  useEffect(() => {
    if (sideSheetData?.gl !== null) {
      const currentGlCode = finalGLList.filter((glCode) => sideSheetData?.gl?.id === glCode.value);
      setSelectedCode(currentGlCode[0]);
    } else {
      setSelectedCode(null);
    }
  }, [sideSheetData, finalGLList]);

  const { mutate: updateGlCode, isLoading: updatingGLCode } = useMutation(async (id: number | null) => axios({
    url: `/api/budget-calendar/event/${sideSheetData?.id}/`,
    method: 'PATCH',
    data: {
      gl: id,
    },
  }), {
    onSuccess: async (): Promise<void> => {
      setSelectedCode(null);
      await queryClient.invalidateQueries('others-events').then();

      await queryClient.invalidateQueries('sidesheet/get-events').then();
      await queryClient.invalidateQueries('prioritized-events').then();
      await queryClient.invalidateQueries('user-assigned-events').then();
      await queryClient.invalidateQueries('allevents/get-events').then();
      await queryClient.invalidateQueries('user-created-events').then();
      enqueueSnackbar('GL Code updated successfully');
    },
  });

  function SelectCustomOption(option: OptionProps<IGLProps>): JSX.Element {
    const { data } = option;
    return (
      <div className='select-custom-option' onClick={(): void => { updateGlCode(data.value); }} aria-hidden='true'>
        {data.name}
      </div>
    );
  }

  function ClearIndicator(): JSX.Element {
    return (
      <IconButton onClick={(): void => { updateGlCode(null); }}>
        <CloseIcon fontSize='small' />
      </IconButton>
    );
  }
  function DownChevron(): JSX.Element {
    return (
      <div>
        <ArrowDropDownIcon fontSize='small' />
      </div>
    );
  }
  return (
    <div className='assignee-sheet-bar'>
      <h6 className='side-sheet-side-label'> G/L Code </h6>
      <div className='assignee-sheet-popover'>
        <div className='popover-btn' aria-hidden='true'>
          {updatingGLCode ? <StradaSpinner open={updatingGLCode} message='Updating' /> : (
            <Select
              className='single-select'
              options={[...finalGLList]}
              value={selectedCode}
              placeholder={<HorizontalRuleIcon />}
              isClearable
              closeMenuOnSelect
              styles={selectStyles}
              getOptionLabel={(val): string => val.name}
              components={{
                Option: SelectCustomOption,
                ClearIndicator,
                DropdownIndicator: selectedCode === null ? DownChevron : null,
                IndicatorSeparator: null,
              }}
            />
          )}

        </div>

      </div>
    </div>
  );
}
