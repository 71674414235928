export const config = {
  appId: '8d51c84c-ab15-46a6-8327-3b278caf4b08',
  redirectUri: 'http://localhost:3000/dashboard',
  scopes: [
    'openid',
    'mail.Send',
    'Calendars.Read',
    'Calendars.ReadWrite',
    'User.Read',
    'Mail.Read',
  ],
};
