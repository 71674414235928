/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import {
  Checkbox,
  Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Grid, Typography, InputBase, Paper, IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import type { SelectChangeEvent } from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import type { RootState } from 'mainStore';
import { StyledTab, StyledTabs } from 'shared-components/components/StyledComponent';
import SelectInput from 'shared-components/inputs/SelectInput';
import StradaLoader from 'shared-components/components/StradaLoader';
import PODetailDialog from 'admin/purchaseOrder/PODetailDialog';
import type { IVendorListing, IStatus, IApprovalDetails } from 'admin/purchaseOrder/types';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles({
  select_design: {
    '&. MuiSelect-select': {
      marginTop: '2px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'none',
    },
  },
  cell_design: {
    color: 'red !important',
    '& .MuiTableCell-root': {
      padding: '0px',
      '& .MuiFormControl-fullWidth': {
        margin: '0px !important',
      },
    },
  },
});

function PurchaseOrderContent(): JSX.Element {
  const queryClient = useQueryClient();
  const classes = useStyles();
  const currentBuilding = useSelector((state: RootState) => state.workspaces.currentBuilding);
  const { poId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedCount, setSelectedCount] = useState(0);
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortBy, setSortBy] = useState('vendor');
  const [searchText, setSearchText] = useState('');
  const [POData, setPOData] = useState<IVendorListing[]>();
  const [filteredPOData, setFilteredPOData] = useState<IVendorListing[]>();
  const [selectedPOData, setSelectedPOData] = useState<IVendorListing[]>();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  useEffect(() => {
    if (POData !== undefined && POData.length > 0 && poId !== undefined) {
      let index = -1;
      for (let i = 0; i < POData.length; i += 1) {
        if (POData[i].id === Number(poId)) {
          index = i;
        }
      }
      setSelectedIndex(index);
    }
  }, [poId, POData]);

  const StatusList = [
    { value: 0, name: 'Open' },
    { value: 1, name: 'Closed' },
  ];
  const { isLoading } = useQuery(
    'get-PO-list',
    async () => axios({
      url: `api/purchase-orders/?property=${currentBuilding.id}`,
      method: 'GET',
    }),
    {
      onSuccess: (res) => {
        if (res.data !== undefined) {
          const data: IVendorListing[] = res.data.result;
          setPOData(data);
          setFilteredPOData(data);
        }
      },
    },
  );

  const { mutate: updateStatus, isLoading: updating } = useMutation(async ({ closed, id }: { closed: boolean; id: number | undefined }) => axios({
    url: `api/purchase-orders/${id}/`,
    method: 'PATCH',
    data: { closed },
  }), {
    onSuccess: async () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      await queryClient.invalidateQueries('get-PO-list').catch()
        .then();
    },

  });

  const { mutate: deletePO, isLoading: deleting } = useMutation(async (id: number) => axios({
    url: `api/purchase-orders/${id}/`,
    method: 'DELETE',
  }), {
    onSuccess: async () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      await queryClient.invalidateQueries('get-PO-list').catch()
        .then();
      enqueueSnackbar('Deleted Successfully');
    },
  });

  const { mutate: sendPOApproval, isLoading: sending } = useMutation(async (data: IApprovalDetails[]) => axios({
    url: 'api/purchase-orders/send_po_email/',
    method: 'POST',
    data: { data },
  }), {
    onSuccess: async () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      await queryClient.invalidateQueries('get-PO-list').catch()
        .then();
      enqueueSnackbar('PO sent for approval.');
    },
  });

  const getSelectedCount = (): void => {
    let count = 0;
    const newData: IVendorListing[] = [];
    if (filteredPOData !== undefined && filteredPOData.length > 0) {
      filteredPOData.forEach((item) => {
        if (item.selected) {
          count += 1;
          newData.push(item);
        }
      });
    }
    setSelectedPOData(newData);
    setSelectedCount(count);
  };

  const handleTabChange = (event: React.SyntheticEvent, index: number): void => {
    if (event.type === 'click') {
      setTabIndex(index);
      const filterPOData: IVendorListing[] = [];
      if (index === 0) {
        POData?.forEach((purchase) => {
          purchase.selected = false;
          filterPOData.push(purchase);
        });
      } else if (index === 1) {
        POData?.forEach((purchase) => {
          purchase.selected = false;
          if (purchase.status === 2) {
            filterPOData.push(purchase);
          }
        });
      } else if (index === 2) {
        POData?.forEach((purchase) => {
          purchase.selected = false;
          if (purchase.status === 1) {
            filterPOData.push(purchase);
          }
        });
      } else if (index === 3) {
        POData?.forEach((purchase) => {
          purchase.selected = false;
          if (purchase.status === 0) {
            filterPOData.push(purchase);
          }
        });
      } else if (index === 4) {
        POData?.forEach((purchase) => {
          purchase.selected = false;
          if (purchase.status === 3) {
            filterPOData.push(purchase);
          }
        });
      }
      setFilteredPOData(filterPOData);
      getSelectedCount();
    }
  };

  const getStatusValue = (value: number | undefined): JSX.Element => {
    if (value === 0) {
      return <div className='f-badge not-approved'>Not Approved</div>;
    } if (value === 1) {
      return <div className='f-badge waiting'>Waiting for Approval</div>;
    } if (value === 2) {
      return <div className='f-badge approved'>Approved</div>;
    } if (value === 3) {
      return <div className='f-badge rejected'>Rejected</div>;
    }
    return <div />;
  };

  const handleSelect = (type: string, event: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    if (filteredPOData !== undefined && filteredPOData.length > 0) {
      if (type === 'single') {
        const filteredPODataClone = [...filteredPOData];
        filteredPODataClone[index].selected = event.target.checked;
        setFilteredPOData(filteredPODataClone);
      } else if (type === 'all') {
        const filteredPODataCloneTwo = [...filteredPOData];
        const updatedFilterdData = filteredPODataCloneTwo.map((item: IVendorListing) => {
          item.selected = event.target.checked;
          return item;
        });
        setFilteredPOData(updatedFilterdData);
      }
    }
    getSelectedCount();
  };

  const sortData = (): void => {
    if (sortBy === 'vendor') {
      filteredPOData?.sort(
        (a, b) => {
          if (a.vendor !== null && b.vendor !== null && a.vendor.label.toUpperCase().trim() > b.vendor.label.toUpperCase().trim()) {
            return sortOrder === 'desc' ? 1 : -1;
          }
          if (a.vendor !== null && b.vendor !== null && a.vendor.label.toUpperCase().trim() === b.vendor.label.toUpperCase().trim()) {
            return 0;
          }
          return sortOrder === 'desc' ? -1 : 1;
        },
      );
      setFilteredPOData(filteredPOData);
    } else if (sortBy === 'date') {
      filteredPOData?.sort(
        (a, b) => {
          const d1 = new Date(a.created_at);
          const d2 = new Date(b.created_at);
          if (d1.getTime() > d2.getTime()) {
            return sortOrder === 'desc' ? 1 : -1;
          }
          if (d1.getTime() === d2.getTime()) {
            return 0;
          }
          return sortOrder === 'desc' ? -1 : 1;
        },
      );
      setFilteredPOData(filteredPOData);
    } else if (sortBy === 'status') {
      filteredPOData?.sort(
        (a, b) => {
          if (a.closed.toString() > b.closed.toString()) { return sortOrder === 'desc' ? 1 : -1; }
          if (a.closed.toString() === b.closed.toString()) { return 0; }
          return sortOrder === 'desc' ? -1 : 1;
        },
      );
      setFilteredPOData(filteredPOData);
    } else if (sortBy === 'amount') {
      filteredPOData?.sort(
        (a, b) => {
          if (parseFloat(a.total) > parseFloat(b.total)) return sortOrder === 'desc' ? 1 : -1;
          if (parseFloat(a.total) === parseFloat(b.total)) {
            return 0;
          } return sortOrder === 'desc' ? -1 : 1;
        },
      );
      setFilteredPOData(filteredPOData);
    } else if (sortBy === 'approval') {
      filteredPOData?.sort(
        (a, b) => {
          if (a.status > b.status) return sortOrder === 'desc' ? 1 : -1;
          if (a.status === b.status) {
            return 0;
          } return sortOrder === 'desc' ? -1 : 1;
        },
      );
      setFilteredPOData(filteredPOData);
    } else {
      setFilteredPOData(filteredPOData);
    }
  };

  const handleSort = (val: string): void => {
    if (sortBy === val) {
      if (sortOrder === 'asc') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }
    } else {
      setSortBy(val);
      setSortOrder('asc');
    }
    sortData();
  };

  const handleRowClick = (index: number): void => {
    setSelectedIndex(index);
  };

  const handleStatusChange = (event: SelectChangeEvent, index: number): void => {
    let status = false;
    if (Number(event.target.value) === 1) {
      status = true;
    }
    updateStatus({ closed: status, id: POData?.[index].id });
  };

  const getStatus = (closed: boolean): IStatus => {
    if (closed) return { value: '1', name: 'Closed' };
    return { value: '0', name: 'Open' };
  };

  const handleDelete = (): void => {
    if (selectedPOData !== undefined && selectedPOData.length > 0) {
      selectedPOData.forEach((item) => {
        if (item.selected) {
          deletePO(item.id);
        }
      });
    }
  };

  const handleSendApproval = (): void => {
    if (selectedPOData !== undefined && selectedPOData.length > 0) {
      const dataForApproval: IApprovalDetails[] = [];
      selectedPOData.forEach((item) => {
        if (item.selected) {
          const data = { id: item.id, managerApproval: item.manager_approval };
          dataForApproval.push(data);
          item.selected = false;
        }
      });
      sendPOApproval(dataForApproval);
      setTabIndex(0);
      getSelectedCount();
    }
  };

  const handleSearch = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      setSearchText(searchText);
    }
  };

  return (
    <div style={{ display: 'flex' }} className='Main-purchase-orders'>
      <StradaLoader open={isLoading || updating || deleting || sending} />
      <div className='purchases'>
        <Grid container mt={2} spacing={3} className='search-field-wrapper'>
          <Grid item sm={6}>
            <Typography className='search-field-typo '>Purchase Order</Typography>
          </Grid>
          <Grid item sm={6} className='search-field'>
            <Paper elevation={0} className='search-paper'>
              <InputBase
                value=''
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => { setSearchText(e.target.value); }}
                onBlur={(): void => { setSearchText(searchText); }}
                onKeyPress={handleSearch}
                placeholder='Search'
                endAdornment={(
                  <IconButton style={{ padding: 0 }} onClick={(): void => { setSearchText(searchText); }}>
                    <SearchIcon className='search-icon' />
                  </IconButton>
                )}
              />
            </Paper>
          </Grid>
        </Grid>

        <div className='mt-4 mb-4 border mainarea-PO'>
          <StyledTabs value={tabIndex} onChange={handleTabChange}>
            <StyledTab label='All' />
            <StyledTab label='Approved' />
            <StyledTab label='Waiting for Approval' />
            <StyledTab label='Not Approved' />
            <StyledTab label='Rejected' />
          </StyledTabs>
          <Table aria-label='simple table'>
            <TableHead>
              {selectedCount === 0 ? (
                <TableRow>
                  <TableCell align='center' style={{ width: '6%' }}>
                    <Checkbox
                      id='selectAll'
                      name='selectAll'
                      checked={filteredPOData && filteredPOData.length > 0 && selectedCount === filteredPOData.length}
                      onChange={(e): void => { handleSelect('all', e, -1); }}
                    />
                  </TableCell>
                  <TableCell align='left' style={{ fontFamily: 'Roboto-Medium', width: '20%' }}>
                    <TableSortLabel
                      active={sortBy === 'vendor'}
                      direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                      onClick={(): void => { handleSort('vendor'); }}
                    >
                      Vendor
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align='left' style={{ fontFamily: 'Roboto-Medium', width: '20%' }}>
                    <TableSortLabel
                      active={sortBy === 'date'}
                      direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                      onClick={(): void => { handleSort('date'); }}
                    >
                      Date Processed
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align='left' style={{ fontFamily: 'Roboto-Medium', width: '10%' }}>
                    <TableSortLabel
                      active={sortBy === 'status'}
                      direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                      onClick={(): void => { handleSort('status'); }}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align='right' style={{ fontFamily: 'Roboto-Medium', width: '10%', paddingRight: '0px' }}>
                    <TableSortLabel
                      active={sortBy === 'amount'}
                      direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                      onClick={(): void => { handleSort('amount'); }}
                    >
                      $Amount
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align='right' style={{ fontFamily: 'Roboto-Medium', width: '30%' }}>
                    <TableSortLabel
                      active={sortBy === 'approval'}
                      direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                      onClick={(): void => { handleSort('approval'); }}
                    >
                      Approval
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              )
                : (
                  <TableRow>
                    <TableCell align='center' style={{ fontFamily: 'Roboto-Medium', width: '6%' }}>
                      <Checkbox
                        id='selectAll'
                        checked={filteredPOData && filteredPOData.length > 0 && selectedCount === filteredPOData.length}
                        name='selectAll'
                        onChange={(e): void => { handleSelect('all', e, -1); }}
                      />
                    </TableCell>
                    <TableCell align='left' style={{ fontFamily: 'Roboto-Medium', width: '12%' }}>
                      <div>
                        {selectedCount}
                        {' '}
                        Selected
                      </div>
                    </TableCell>
                    <TableCell align='left' style={{ fontFamily: 'Roboto-Medium', width: '20%' }}>
                      <div aria-hidden='true' onClick={handleSendApproval} className='cursor-pointer'>
                        <i className='fas fa-envelope me-1' style={{ color: '#00CFA1' }} />
                        Send For Approval
                      </div>
                    </TableCell>
                    <TableCell align='left' style={{ fontFamily: 'Roboto-Medium', width: '10%' }}>
                      <div aria-hidden='true' onClick={handleDelete} className='cursor-pointer'>
                        <i className='far fa-trash-alt me-1' style={{ color: '#00CFA1' }} />
                        Delete
                      </div>
                    </TableCell>
                    <TableCell align='left' style={{ width: '20%' }} />
                    <TableCell align='left' style={{ width: '20%' }} />
                    <TableCell align='left' style={{ width: '20%' }} />
                  </TableRow>
                )}
            </TableHead>
            {filteredPOData && filteredPOData.length > 0 && (
              <TableBody>
                {filteredPOData.map((item, index) => (
                  <TableRow key={item.id} hover className={classes.cell_design} style={{ backgroundColor: item.selected ? 'rgba(0, 207, 161, 0.08)' : '' }}>
                    <TableCell align='center'>
                      <div className='checkbox-cois'>
                        <Checkbox
                          id='single'
                          name='single'
                          checked={Boolean(item.selected)}
                          onChange={(e): void => { handleSelect('single', e, index); }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align='left' onClick={(): void => { handleRowClick(index); }}>{item.vendor !== null ? item.vendor.label : '' }</TableCell>
                    <TableCell align='left' onClick={(): void => { handleRowClick(index); }}>{moment(item.created_at).format('MM/DD/YYYY hh:mm: A')}</TableCell>
                    <TableCell align='left'>
                      {!item.status_disable_check
                        ? (
                          <SelectInput
                            value={getStatus(item.closed).value}
                            name='status'
                            label=''
                            onChange={(obj): void => { handleStatusChange(obj, index); }}
                            options={StatusList}
                            className={classes.select_design}
                            showPleaseSelect={false}
                          />

                        )
                        : <p style={{ padding: '0 12px' }}>Open</p>}
                    </TableCell>
                    <TableCell align='right' onClick={(): void => { handleRowClick(index); }}>
                      $
                      {item.total}
                    </TableCell>
                    <TableCell align='right' onClick={(): void => { handleRowClick(index); }}>{ getStatusValue(item.status)}</TableCell>
                    <TableCell align='left' style={{ width: '4%' }} />
                  </TableRow>
                ))}
              </TableBody>
            ) }
          </Table>
        </div>
      </div>

      <PODetailDialog
        open={selectedIndex >= 0}
        handleClose={(): void => { setSelectedIndex(-1); }}
        selectedData={POData?.[selectedIndex]}
        getStatusValue={getStatusValue}
      />
    </div>
  );
}

export default PurchaseOrderContent;
